/* common style */
body {
  margin: 0;
  padding: 0;
  font-family: "GothamNarrow", Helvetica, sans-serif; }

/* end-common style */
/* modules */
/* fontFamily definition (with fallback fonts) */
/* color */
/* height */
/* PingFang SC */
@font-face {
  font-family: "PingFang SC";
  /* font-display `swap`(https://developers.google.com/web/fundamentals/performance/optimizing-content-efficiency/webfont-optimization) */
  font-display: swap;
  src: local("PingFang SC"), url("../fonts/PingFangSC-Regular.woff2") format("woff2"), url("../fonts/PingFangSC-Regular.woff") format("woff"), url("../fonts/PingFangSC-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

/* Lekton */
@font-face {
  font-family: 'Lekton';
  src: url("../fonts/Lekton-Normal.woff2") format("woff2"), url("../fonts/Lekton-Normal.woff") format("woff"), url("../fonts/Lekton-Normal.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Lekton';
  src: url("../fonts/Lekton-Bold.woff2") format("woff2"), url("../fonts/Lekton-Bold.woff") format("woff"), url("../fonts/Lekton-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal; }

@font-face {
  font-family: 'Lekton';
  src: url("../fonts/Lekton-Italic.woff2") format("woff2"), url("../fonts/Lekton-Italic.woff") format("woff"), url("../fonts/Lekton-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic; }

/* Didot */
@font-face {
  font-family: 'Didot';
  src: url("../fonts/Didot-Normal.woff2") format("woff2"), url("../fonts/Didot-Normal.woff") format("woff"), url("../fonts/Didot-Normal.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Didot';
  src: url("../fonts/Didot-Bold.woff2") format("woff2"), url("../fonts/Didot-Bold.woff") format("woff"), url("../fonts/Didot-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal; }

@font-face {
  font-family: 'Didot';
  src: url("../fonts/Didot-Italic.woff2") format("woff2"), url("../fonts/Didot-Italic.woff") format("woff"), url("../fonts/Didot-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic; }

/* GothamNarrow-Book */
@font-face {
  font-family: "GothamNarrow";
  src: local("GothamNarrow-Book"), url("../fonts/GothamNarrow-Book.woff2") format("woff2"), url("../fonts/GothamNarrow-Book.woff") format("woff"), url("../fonts/GothamNarrow-Book.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

.btnLinkBaseStyle {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: inline;
  margin: 0;
  padding: 0; }
  .btnLinkBaseStyle:focus {
    outline: none; }

.heroContainer .heroContent {
  min-height: 100vh; }
  @media (min-width: 768px) {
    .heroContainer .heroContent {
      min-height: calc(100vh - 93px); } }
  @media (min-width: 992px) {
    .heroContainer .heroContent {
      min-height: calc(100vh - 109px); } }
  @media (min-width: 1200px) {
    .heroContainer .heroContent {
      min-height: calc(100vh - 157px); } }

.heroContainer .topLeftLogo {
  position: absolute;
  width: 35px;
  height: auto;
  z-index: 1030;
  transform: rotate(0deg);
  transition-timing-function: linear;
  transition: all 3s; }
  .heroContainer .topLeftLogo:hover {
    transform: rotate(360deg); }

.heroContainer .heroContent h5.gopomoText {
  font-family: "GothamNarrow", Helvetica, sans-serif; }

.heroContainer .heroContent div.titleContainer {
  width: 100px;
  height: 100px;
  margin-bottom: 100px;
  position: relative;
  transform: translateY(0);
  margin: 3rem auto auto auto; }
  @media (min-width: 576px) {
    .heroContainer .heroContent div.titleContainer {
      margin: 60px auto; } }
  @media (min-width: 768px) {
    .heroContainer .heroContent div.titleContainer {
      margin: auto;
      transform: translateY(-100%); } }
  .heroContainer .heroContent div.titleContainer h3 {
    font-family: "Didot", Helvetica, sans-serif;
    font-size: 18px; }
  .heroContainer .heroContent div.titleContainer span {
    position: absolute;
    height: 1px;
    width: 120px;
    background-color: black;
    transform: rotate(-40deg); }
    .heroContainer .heroContent div.titleContainer span.topLeft {
      top: 0;
      left: -50%;
      margin-bottom: 100px; }
    .heroContainer .heroContent div.titleContainer span.bottomRight {
      bottom: 0;
      right: -50%; }

.heroContainer .heroContent div.splashSubTitleContainer {
  margin-top: 60px; }
  @media (min-width: 576px) {
    .heroContainer .heroContent div.splashSubTitleContainer {
      margin-top: 0; } }
  .heroContainer .heroContent div.splashSubTitleContainer h1 {
    font-size: 2em;
    letter-spacing: 5px;
    color: #333333;
    font-weight: normal;
    font-family: "Didot", Helvetica, sans-serif; }
    @media (min-width: 576px) {
      .heroContainer .heroContent div.splashSubTitleContainer h1 {
        font-size: 3em; } }
    @media (min-width: 992px) {
      .heroContainer .heroContent div.splashSubTitleContainer h1.letterTypingShow {
        letter-spacing: 2px; } }
    .heroContainer .heroContent div.splashSubTitleContainer h1.letterTypingShow div {
      display: inline-flex; }
    .heroContainer .heroContent div.splashSubTitleContainer h1.letterTypingShow span.ani {
      display: inline-block;
      opacity: 0;
      /* setup animation */ }
      .heroContainer .heroContent div.splashSubTitleContainer h1.letterTypingShow span.ani.space {
        min-width: 18px; }
        @media (min-width: 576px) {
          .heroContainer .heroContent div.splashSubTitleContainer h1.letterTypingShow span.ani.space {
            min-width: 15px; } }
      .heroContainer .heroContent div.splashSubTitleContainer h1.letterTypingShow span.ani.spanCount1 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 100ms;
        animation-fill-mode: forwards; }
      .heroContainer .heroContent div.splashSubTitleContainer h1.letterTypingShow span.ani.spanCount2 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 200ms;
        animation-fill-mode: forwards; }
      .heroContainer .heroContent div.splashSubTitleContainer h1.letterTypingShow span.ani.spanCount3 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 300ms;
        animation-fill-mode: forwards; }
      .heroContainer .heroContent div.splashSubTitleContainer h1.letterTypingShow span.ani.spanCount4 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 400ms;
        animation-fill-mode: forwards; }
      .heroContainer .heroContent div.splashSubTitleContainer h1.letterTypingShow span.ani.spanCount5 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 500ms;
        animation-fill-mode: forwards; }
      .heroContainer .heroContent div.splashSubTitleContainer h1.letterTypingShow span.ani.spanCount6 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 600ms;
        animation-fill-mode: forwards; }
      .heroContainer .heroContent div.splashSubTitleContainer h1.letterTypingShow span.ani.spanCount7 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 700ms;
        animation-fill-mode: forwards; }
      .heroContainer .heroContent div.splashSubTitleContainer h1.letterTypingShow span.ani.spanCount8 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 800ms;
        animation-fill-mode: forwards; }
      .heroContainer .heroContent div.splashSubTitleContainer h1.letterTypingShow span.ani.spanCount9 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 900ms;
        animation-fill-mode: forwards; }
      .heroContainer .heroContent div.splashSubTitleContainer h1.letterTypingShow span.ani.spanCount10 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 1000ms;
        animation-fill-mode: forwards; }
      .heroContainer .heroContent div.splashSubTitleContainer h1.letterTypingShow span.ani.spanCount11 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 1100ms;
        animation-fill-mode: forwards; }
      .heroContainer .heroContent div.splashSubTitleContainer h1.letterTypingShow span.ani.spanCount12 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 1200ms;
        animation-fill-mode: forwards; }
      .heroContainer .heroContent div.splashSubTitleContainer h1.letterTypingShow span.ani.spanCount13 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 1300ms;
        animation-fill-mode: forwards; }
      .heroContainer .heroContent div.splashSubTitleContainer h1.letterTypingShow span.ani.spanCount14 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 1400ms;
        animation-fill-mode: forwards; }
      .heroContainer .heroContent div.splashSubTitleContainer h1.letterTypingShow span.ani.spanCount15 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 1500ms;
        animation-fill-mode: forwards; }
      .heroContainer .heroContent div.splashSubTitleContainer h1.letterTypingShow span.ani.spanCount16 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 1600ms;
        animation-fill-mode: forwards; }
      .heroContainer .heroContent div.splashSubTitleContainer h1.letterTypingShow span.ani.spanCount17 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 1700ms;
        animation-fill-mode: forwards; }
      .heroContainer .heroContent div.splashSubTitleContainer h1.letterTypingShow span.ani.spanCount18 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 1800ms;
        animation-fill-mode: forwards; }
      .heroContainer .heroContent div.splashSubTitleContainer h1.letterTypingShow span.ani.spanCount19 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 1900ms;
        animation-fill-mode: forwards; }
      .heroContainer .heroContent div.splashSubTitleContainer h1.letterTypingShow span.ani.spanCount20 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 2000ms;
        animation-fill-mode: forwards; }
      .heroContainer .heroContent div.splashSubTitleContainer h1.letterTypingShow span.ani.spanCount21 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 2100ms;
        animation-fill-mode: forwards; }
      .heroContainer .heroContent div.splashSubTitleContainer h1.letterTypingShow span.ani.spanCount22 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 2200ms;
        animation-fill-mode: forwards; }
      .heroContainer .heroContent div.splashSubTitleContainer h1.letterTypingShow span.ani.spanCount23 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 2300ms;
        animation-fill-mode: forwards; }
      .heroContainer .heroContent div.splashSubTitleContainer h1.letterTypingShow span.ani.spanCount24 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 2400ms;
        animation-fill-mode: forwards; }
      .heroContainer .heroContent div.splashSubTitleContainer h1.letterTypingShow span.ani.spanCount25 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 2500ms;
        animation-fill-mode: forwards; }
      .heroContainer .heroContent div.splashSubTitleContainer h1.letterTypingShow span.ani.spanCount26 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 2600ms;
        animation-fill-mode: forwards; }
      .heroContainer .heroContent div.splashSubTitleContainer h1.letterTypingShow span.ani.spanCount27 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 2700ms;
        animation-fill-mode: forwards; }
      .heroContainer .heroContent div.splashSubTitleContainer h1.letterTypingShow span.ani.spanCount28 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 2800ms;
        animation-fill-mode: forwards; }
      .heroContainer .heroContent div.splashSubTitleContainer h1.letterTypingShow span.ani.spanCount29 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 2900ms;
        animation-fill-mode: forwards; }
      .heroContainer .heroContent div.splashSubTitleContainer h1.letterTypingShow span.ani.spanCount30 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 3000ms;
        animation-fill-mode: forwards; }
      .heroContainer .heroContent div.splashSubTitleContainer h1.letterTypingShow span.ani.spanCount31 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 3100ms;
        animation-fill-mode: forwards; }
      .heroContainer .heroContent div.splashSubTitleContainer h1.letterTypingShow span.ani.spanCount32 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 3200ms;
        animation-fill-mode: forwards; }
      .heroContainer .heroContent div.splashSubTitleContainer h1.letterTypingShow span.ani.spanCount33 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 3300ms;
        animation-fill-mode: forwards; }
      .heroContainer .heroContent div.splashSubTitleContainer h1.letterTypingShow span.ani.spanCount34 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 3400ms;
        animation-fill-mode: forwards; }
      .heroContainer .heroContent div.splashSubTitleContainer h1.letterTypingShow span.ani.spanCount35 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 3500ms;
        animation-fill-mode: forwards; }
      .heroContainer .heroContent div.splashSubTitleContainer h1.letterTypingShow span.ani.spanCount36 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 3600ms;
        animation-fill-mode: forwards; }
      .heroContainer .heroContent div.splashSubTitleContainer h1.letterTypingShow span.ani.spanCount37 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 3700ms;
        animation-fill-mode: forwards; }
      .heroContainer .heroContent div.splashSubTitleContainer h1.letterTypingShow span.ani.spanCount38 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 3800ms;
        animation-fill-mode: forwards; }
      .heroContainer .heroContent div.splashSubTitleContainer h1.letterTypingShow span.ani.spanCount39 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 3900ms;
        animation-fill-mode: forwards; }
      .heroContainer .heroContent div.splashSubTitleContainer h1.letterTypingShow span.ani.spanCount40 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 4000ms;
        animation-fill-mode: forwards; }
      .heroContainer .heroContent div.splashSubTitleContainer h1.letterTypingShow span.ani.spanCount41 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 4100ms;
        animation-fill-mode: forwards; }
      .heroContainer .heroContent div.splashSubTitleContainer h1.letterTypingShow span.ani.spanCount42 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 4200ms;
        animation-fill-mode: forwards; }
      .heroContainer .heroContent div.splashSubTitleContainer h1.letterTypingShow span.ani.spanCount43 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 4300ms;
        animation-fill-mode: forwards; }
      .heroContainer .heroContent div.splashSubTitleContainer h1.letterTypingShow span.ani.spanCount44 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 4400ms;
        animation-fill-mode: forwards; }
      .heroContainer .heroContent div.splashSubTitleContainer h1.letterTypingShow span.ani.spanCount45 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 4500ms;
        animation-fill-mode: forwards; }
      .heroContainer .heroContent div.splashSubTitleContainer h1.letterTypingShow span.ani.spanCount46 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 4600ms;
        animation-fill-mode: forwards; }
      .heroContainer .heroContent div.splashSubTitleContainer h1.letterTypingShow span.ani.spanCount47 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 4700ms;
        animation-fill-mode: forwards; }
      .heroContainer .heroContent div.splashSubTitleContainer h1.letterTypingShow span.ani.spanCount48 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 4800ms;
        animation-fill-mode: forwards; }
      .heroContainer .heroContent div.splashSubTitleContainer h1.letterTypingShow span.ani.spanCount49 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 4900ms;
        animation-fill-mode: forwards; }
      .heroContainer .heroContent div.splashSubTitleContainer h1.letterTypingShow span.ani.spanCount50 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 5000ms;
        animation-fill-mode: forwards; }
      .heroContainer .heroContent div.splashSubTitleContainer h1.letterTypingShow span.ani.spanCount51 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 5100ms;
        animation-fill-mode: forwards; }
      .heroContainer .heroContent div.splashSubTitleContainer h1.letterTypingShow span.ani.spanCount52 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 5200ms;
        animation-fill-mode: forwards; }
      .heroContainer .heroContent div.splashSubTitleContainer h1.letterTypingShow span.ani.spanCount53 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 5300ms;
        animation-fill-mode: forwards; }
      .heroContainer .heroContent div.splashSubTitleContainer h1.letterTypingShow span.ani.spanCount54 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 5400ms;
        animation-fill-mode: forwards; }
      .heroContainer .heroContent div.splashSubTitleContainer h1.letterTypingShow span.ani.spanCount55 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 5500ms;
        animation-fill-mode: forwards; }
      .heroContainer .heroContent div.splashSubTitleContainer h1.letterTypingShow span.ani.spanCount56 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 5600ms;
        animation-fill-mode: forwards; }
      .heroContainer .heroContent div.splashSubTitleContainer h1.letterTypingShow span.ani.spanCount57 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 5700ms;
        animation-fill-mode: forwards; }
      .heroContainer .heroContent div.splashSubTitleContainer h1.letterTypingShow span.ani.spanCount58 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 5800ms;
        animation-fill-mode: forwards; }
      .heroContainer .heroContent div.splashSubTitleContainer h1.letterTypingShow span.ani.spanCount59 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 5900ms;
        animation-fill-mode: forwards; }
      .heroContainer .heroContent div.splashSubTitleContainer h1.letterTypingShow span.ani.spanCount60 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 6000ms;
        animation-fill-mode: forwards; }
      .heroContainer .heroContent div.splashSubTitleContainer h1.letterTypingShow span.ani.spanCount61 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 6100ms;
        animation-fill-mode: forwards; }
      .heroContainer .heroContent div.splashSubTitleContainer h1.letterTypingShow span.ani.spanCount62 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 6200ms;
        animation-fill-mode: forwards; }
      .heroContainer .heroContent div.splashSubTitleContainer h1.letterTypingShow span.ani.spanCount63 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 6300ms;
        animation-fill-mode: forwards; }
      .heroContainer .heroContent div.splashSubTitleContainer h1.letterTypingShow span.ani.spanCount64 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 6400ms;
        animation-fill-mode: forwards; }
      .heroContainer .heroContent div.splashSubTitleContainer h1.letterTypingShow span.ani.spanCount65 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 6500ms;
        animation-fill-mode: forwards; }
      .heroContainer .heroContent div.splashSubTitleContainer h1.letterTypingShow span.ani.spanCount66 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 6600ms;
        animation-fill-mode: forwards; }
      .heroContainer .heroContent div.splashSubTitleContainer h1.letterTypingShow span.ani.spanCount67 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 6700ms;
        animation-fill-mode: forwards; }
      .heroContainer .heroContent div.splashSubTitleContainer h1.letterTypingShow span.ani.spanCount68 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 6800ms;
        animation-fill-mode: forwards; }
      .heroContainer .heroContent div.splashSubTitleContainer h1.letterTypingShow span.ani.spanCount69 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 6900ms;
        animation-fill-mode: forwards; }
      .heroContainer .heroContent div.splashSubTitleContainer h1.letterTypingShow span.ani.spanCount70 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 7000ms;
        animation-fill-mode: forwards; }
      .heroContainer .heroContent div.splashSubTitleContainer h1.letterTypingShow span.ani.spanCount71 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 7100ms;
        animation-fill-mode: forwards; }
      .heroContainer .heroContent div.splashSubTitleContainer h1.letterTypingShow span.ani.spanCount72 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 7200ms;
        animation-fill-mode: forwards; }
      .heroContainer .heroContent div.splashSubTitleContainer h1.letterTypingShow span.ani.spanCount73 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 7300ms;
        animation-fill-mode: forwards; }
      .heroContainer .heroContent div.splashSubTitleContainer h1.letterTypingShow span.ani.spanCount74 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 7400ms;
        animation-fill-mode: forwards; }
      .heroContainer .heroContent div.splashSubTitleContainer h1.letterTypingShow span.ani.spanCount75 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 7500ms;
        animation-fill-mode: forwards; }
      .heroContainer .heroContent div.splashSubTitleContainer h1.letterTypingShow span.ani.spanCount76 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 7600ms;
        animation-fill-mode: forwards; }
      .heroContainer .heroContent div.splashSubTitleContainer h1.letterTypingShow span.ani.spanCount77 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 7700ms;
        animation-fill-mode: forwards; }
      .heroContainer .heroContent div.splashSubTitleContainer h1.letterTypingShow span.ani.spanCount78 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 7800ms;
        animation-fill-mode: forwards; }
      .heroContainer .heroContent div.splashSubTitleContainer h1.letterTypingShow span.ani.spanCount79 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 7900ms;
        animation-fill-mode: forwards; }
      .heroContainer .heroContent div.splashSubTitleContainer h1.letterTypingShow span.ani.spanCount80 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 8000ms;
        animation-fill-mode: forwards; }
      .heroContainer .heroContent div.splashSubTitleContainer h1.letterTypingShow span.ani.spanCount81 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 8100ms;
        animation-fill-mode: forwards; }
      .heroContainer .heroContent div.splashSubTitleContainer h1.letterTypingShow span.ani.spanCount82 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 8200ms;
        animation-fill-mode: forwards; }
      .heroContainer .heroContent div.splashSubTitleContainer h1.letterTypingShow span.ani.spanCount83 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 8300ms;
        animation-fill-mode: forwards; }
      .heroContainer .heroContent div.splashSubTitleContainer h1.letterTypingShow span.ani.spanCount84 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 8400ms;
        animation-fill-mode: forwards; }
      .heroContainer .heroContent div.splashSubTitleContainer h1.letterTypingShow span.ani.spanCount85 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 8500ms;
        animation-fill-mode: forwards; }
      .heroContainer .heroContent div.splashSubTitleContainer h1.letterTypingShow span.ani.spanCount86 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 8600ms;
        animation-fill-mode: forwards; }
      .heroContainer .heroContent div.splashSubTitleContainer h1.letterTypingShow span.ani.spanCount87 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 8700ms;
        animation-fill-mode: forwards; }
      .heroContainer .heroContent div.splashSubTitleContainer h1.letterTypingShow span.ani.spanCount88 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 8800ms;
        animation-fill-mode: forwards; }
      .heroContainer .heroContent div.splashSubTitleContainer h1.letterTypingShow span.ani.spanCount89 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 8900ms;
        animation-fill-mode: forwards; }
      .heroContainer .heroContent div.splashSubTitleContainer h1.letterTypingShow span.ani.spanCount90 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 9000ms;
        animation-fill-mode: forwards; }
      .heroContainer .heroContent div.splashSubTitleContainer h1.letterTypingShow span.ani.spanCount91 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 9100ms;
        animation-fill-mode: forwards; }
      .heroContainer .heroContent div.splashSubTitleContainer h1.letterTypingShow span.ani.spanCount92 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 9200ms;
        animation-fill-mode: forwards; }
      .heroContainer .heroContent div.splashSubTitleContainer h1.letterTypingShow span.ani.spanCount93 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 9300ms;
        animation-fill-mode: forwards; }
      .heroContainer .heroContent div.splashSubTitleContainer h1.letterTypingShow span.ani.spanCount94 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 9400ms;
        animation-fill-mode: forwards; }
      .heroContainer .heroContent div.splashSubTitleContainer h1.letterTypingShow span.ani.spanCount95 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 9500ms;
        animation-fill-mode: forwards; }
      .heroContainer .heroContent div.splashSubTitleContainer h1.letterTypingShow span.ani.spanCount96 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 9600ms;
        animation-fill-mode: forwards; }
      .heroContainer .heroContent div.splashSubTitleContainer h1.letterTypingShow span.ani.spanCount97 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 9700ms;
        animation-fill-mode: forwards; }
      .heroContainer .heroContent div.splashSubTitleContainer h1.letterTypingShow span.ani.spanCount98 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 9800ms;
        animation-fill-mode: forwards; }
      .heroContainer .heroContent div.splashSubTitleContainer h1.letterTypingShow span.ani.spanCount99 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 9900ms;
        animation-fill-mode: forwards; }
      .heroContainer .heroContent div.splashSubTitleContainer h1.letterTypingShow span.ani.spanCount100 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 10000ms;
        animation-fill-mode: forwards; }
  .heroContainer .heroContent div.splashSubTitleContainer h5 {
    font-family: "Didot", Helvetica, sans-serif; }
    .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow div {
      display: inline-flex; }
    .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani {
      display: inline-block;
      opacity: 0;
      /* setup animation */ }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount1 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 25ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount1:lang(zh-hant) {
          animation-delay: 75ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount2 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 50ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount2:lang(zh-hant) {
          animation-delay: 150ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount3 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 75ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount3:lang(zh-hant) {
          animation-delay: 225ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount4 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 100ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount4:lang(zh-hant) {
          animation-delay: 300ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount5 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 125ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount5:lang(zh-hant) {
          animation-delay: 375ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount6 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 150ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount6:lang(zh-hant) {
          animation-delay: 450ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount7 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 175ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount7:lang(zh-hant) {
          animation-delay: 525ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount8 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 200ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount8:lang(zh-hant) {
          animation-delay: 600ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount9 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 225ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount9:lang(zh-hant) {
          animation-delay: 675ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount10 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 250ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount10:lang(zh-hant) {
          animation-delay: 750ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount11 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 275ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount11:lang(zh-hant) {
          animation-delay: 825ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount12 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 300ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount12:lang(zh-hant) {
          animation-delay: 900ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount13 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 325ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount13:lang(zh-hant) {
          animation-delay: 975ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount14 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 350ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount14:lang(zh-hant) {
          animation-delay: 1050ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount15 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 375ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount15:lang(zh-hant) {
          animation-delay: 1125ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount16 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 400ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount16:lang(zh-hant) {
          animation-delay: 1200ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount17 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 425ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount17:lang(zh-hant) {
          animation-delay: 1275ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount18 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 450ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount18:lang(zh-hant) {
          animation-delay: 1350ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount19 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 475ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount19:lang(zh-hant) {
          animation-delay: 1425ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount20 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 500ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount20:lang(zh-hant) {
          animation-delay: 1500ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount21 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 525ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount21:lang(zh-hant) {
          animation-delay: 1575ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount22 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 550ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount22:lang(zh-hant) {
          animation-delay: 1650ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount23 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 575ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount23:lang(zh-hant) {
          animation-delay: 1725ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount24 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 600ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount24:lang(zh-hant) {
          animation-delay: 1800ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount25 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 625ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount25:lang(zh-hant) {
          animation-delay: 1875ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount26 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 650ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount26:lang(zh-hant) {
          animation-delay: 1950ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount27 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 675ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount27:lang(zh-hant) {
          animation-delay: 2025ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount28 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 700ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount28:lang(zh-hant) {
          animation-delay: 2100ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount29 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 725ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount29:lang(zh-hant) {
          animation-delay: 2175ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount30 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 750ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount30:lang(zh-hant) {
          animation-delay: 2250ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount31 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 775ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount31:lang(zh-hant) {
          animation-delay: 2325ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount32 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 800ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount32:lang(zh-hant) {
          animation-delay: 2400ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount33 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 825ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount33:lang(zh-hant) {
          animation-delay: 2475ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount34 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 850ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount34:lang(zh-hant) {
          animation-delay: 2550ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount35 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 875ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount35:lang(zh-hant) {
          animation-delay: 2625ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount36 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 900ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount36:lang(zh-hant) {
          animation-delay: 2700ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount37 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 925ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount37:lang(zh-hant) {
          animation-delay: 2775ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount38 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 950ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount38:lang(zh-hant) {
          animation-delay: 2850ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount39 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 975ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount39:lang(zh-hant) {
          animation-delay: 2925ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount40 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 1000ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount40:lang(zh-hant) {
          animation-delay: 3000ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount41 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 1025ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount41:lang(zh-hant) {
          animation-delay: 3075ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount42 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 1050ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount42:lang(zh-hant) {
          animation-delay: 3150ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount43 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 1075ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount43:lang(zh-hant) {
          animation-delay: 3225ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount44 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 1100ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount44:lang(zh-hant) {
          animation-delay: 3300ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount45 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 1125ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount45:lang(zh-hant) {
          animation-delay: 3375ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount46 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 1150ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount46:lang(zh-hant) {
          animation-delay: 3450ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount47 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 1175ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount47:lang(zh-hant) {
          animation-delay: 3525ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount48 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 1200ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount48:lang(zh-hant) {
          animation-delay: 3600ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount49 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 1225ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount49:lang(zh-hant) {
          animation-delay: 3675ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount50 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 1250ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount50:lang(zh-hant) {
          animation-delay: 3750ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount51 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 1275ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount51:lang(zh-hant) {
          animation-delay: 3825ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount52 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 1300ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount52:lang(zh-hant) {
          animation-delay: 3900ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount53 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 1325ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount53:lang(zh-hant) {
          animation-delay: 3975ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount54 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 1350ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount54:lang(zh-hant) {
          animation-delay: 4050ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount55 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 1375ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount55:lang(zh-hant) {
          animation-delay: 4125ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount56 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 1400ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount56:lang(zh-hant) {
          animation-delay: 4200ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount57 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 1425ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount57:lang(zh-hant) {
          animation-delay: 4275ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount58 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 1450ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount58:lang(zh-hant) {
          animation-delay: 4350ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount59 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 1475ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount59:lang(zh-hant) {
          animation-delay: 4425ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount60 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 1500ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount60:lang(zh-hant) {
          animation-delay: 4500ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount61 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 1525ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount61:lang(zh-hant) {
          animation-delay: 4575ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount62 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 1550ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount62:lang(zh-hant) {
          animation-delay: 4650ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount63 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 1575ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount63:lang(zh-hant) {
          animation-delay: 4725ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount64 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 1600ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount64:lang(zh-hant) {
          animation-delay: 4800ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount65 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 1625ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount65:lang(zh-hant) {
          animation-delay: 4875ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount66 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 1650ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount66:lang(zh-hant) {
          animation-delay: 4950ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount67 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 1675ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount67:lang(zh-hant) {
          animation-delay: 5025ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount68 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 1700ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount68:lang(zh-hant) {
          animation-delay: 5100ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount69 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 1725ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount69:lang(zh-hant) {
          animation-delay: 5175ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount70 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 1750ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount70:lang(zh-hant) {
          animation-delay: 5250ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount71 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 1775ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount71:lang(zh-hant) {
          animation-delay: 5325ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount72 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 1800ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount72:lang(zh-hant) {
          animation-delay: 5400ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount73 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 1825ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount73:lang(zh-hant) {
          animation-delay: 5475ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount74 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 1850ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount74:lang(zh-hant) {
          animation-delay: 5550ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount75 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 1875ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount75:lang(zh-hant) {
          animation-delay: 5625ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount76 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 1900ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount76:lang(zh-hant) {
          animation-delay: 5700ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount77 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 1925ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount77:lang(zh-hant) {
          animation-delay: 5775ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount78 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 1950ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount78:lang(zh-hant) {
          animation-delay: 5850ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount79 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 1975ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount79:lang(zh-hant) {
          animation-delay: 5925ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount80 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 2000ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount80:lang(zh-hant) {
          animation-delay: 6000ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount81 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 2025ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount81:lang(zh-hant) {
          animation-delay: 6075ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount82 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 2050ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount82:lang(zh-hant) {
          animation-delay: 6150ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount83 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 2075ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount83:lang(zh-hant) {
          animation-delay: 6225ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount84 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 2100ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount84:lang(zh-hant) {
          animation-delay: 6300ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount85 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 2125ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount85:lang(zh-hant) {
          animation-delay: 6375ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount86 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 2150ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount86:lang(zh-hant) {
          animation-delay: 6450ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount87 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 2175ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount87:lang(zh-hant) {
          animation-delay: 6525ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount88 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 2200ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount88:lang(zh-hant) {
          animation-delay: 6600ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount89 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 2225ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount89:lang(zh-hant) {
          animation-delay: 6675ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount90 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 2250ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount90:lang(zh-hant) {
          animation-delay: 6750ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount91 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 2275ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount91:lang(zh-hant) {
          animation-delay: 6825ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount92 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 2300ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount92:lang(zh-hant) {
          animation-delay: 6900ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount93 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 2325ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount93:lang(zh-hant) {
          animation-delay: 6975ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount94 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 2350ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount94:lang(zh-hant) {
          animation-delay: 7050ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount95 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 2375ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount95:lang(zh-hant) {
          animation-delay: 7125ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount96 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 2400ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount96:lang(zh-hant) {
          animation-delay: 7200ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount97 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 2425ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount97:lang(zh-hant) {
          animation-delay: 7275ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount98 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 2450ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount98:lang(zh-hant) {
          animation-delay: 7350ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount99 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 2475ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount99:lang(zh-hant) {
          animation-delay: 7425ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount100 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 2500ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount100:lang(zh-hant) {
          animation-delay: 7500ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount101 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 2525ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount101:lang(zh-hant) {
          animation-delay: 7575ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount102 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 2550ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount102:lang(zh-hant) {
          animation-delay: 7650ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount103 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 2575ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount103:lang(zh-hant) {
          animation-delay: 7725ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount104 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 2600ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount104:lang(zh-hant) {
          animation-delay: 7800ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount105 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 2625ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount105:lang(zh-hant) {
          animation-delay: 7875ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount106 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 2650ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount106:lang(zh-hant) {
          animation-delay: 7950ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount107 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 2675ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount107:lang(zh-hant) {
          animation-delay: 8025ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount108 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 2700ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount108:lang(zh-hant) {
          animation-delay: 8100ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount109 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 2725ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount109:lang(zh-hant) {
          animation-delay: 8175ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount110 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 2750ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount110:lang(zh-hant) {
          animation-delay: 8250ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount111 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 2775ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount111:lang(zh-hant) {
          animation-delay: 8325ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount112 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 2800ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount112:lang(zh-hant) {
          animation-delay: 8400ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount113 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 2825ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount113:lang(zh-hant) {
          animation-delay: 8475ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount114 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 2850ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount114:lang(zh-hant) {
          animation-delay: 8550ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount115 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 2875ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount115:lang(zh-hant) {
          animation-delay: 8625ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount116 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 2900ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount116:lang(zh-hant) {
          animation-delay: 8700ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount117 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 2925ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount117:lang(zh-hant) {
          animation-delay: 8775ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount118 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 2950ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount118:lang(zh-hant) {
          animation-delay: 8850ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount119 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 2975ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount119:lang(zh-hant) {
          animation-delay: 8925ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount120 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 3000ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount120:lang(zh-hant) {
          animation-delay: 9000ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount121 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 3025ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount121:lang(zh-hant) {
          animation-delay: 9075ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount122 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 3050ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount122:lang(zh-hant) {
          animation-delay: 9150ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount123 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 3075ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount123:lang(zh-hant) {
          animation-delay: 9225ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount124 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 3100ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount124:lang(zh-hant) {
          animation-delay: 9300ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount125 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 3125ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount125:lang(zh-hant) {
          animation-delay: 9375ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount126 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 3150ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount126:lang(zh-hant) {
          animation-delay: 9450ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount127 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 3175ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount127:lang(zh-hant) {
          animation-delay: 9525ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount128 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 3200ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount128:lang(zh-hant) {
          animation-delay: 9600ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount129 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 3225ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount129:lang(zh-hant) {
          animation-delay: 9675ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount130 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 3250ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount130:lang(zh-hant) {
          animation-delay: 9750ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount131 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 3275ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount131:lang(zh-hant) {
          animation-delay: 9825ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount132 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 3300ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount132:lang(zh-hant) {
          animation-delay: 9900ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount133 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 3325ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount133:lang(zh-hant) {
          animation-delay: 9975ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount134 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 3350ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount134:lang(zh-hant) {
          animation-delay: 10050ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount135 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 3375ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount135:lang(zh-hant) {
          animation-delay: 10125ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount136 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 3400ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount136:lang(zh-hant) {
          animation-delay: 10200ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount137 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 3425ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount137:lang(zh-hant) {
          animation-delay: 10275ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount138 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 3450ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount138:lang(zh-hant) {
          animation-delay: 10350ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount139 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 3475ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount139:lang(zh-hant) {
          animation-delay: 10425ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount140 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 3500ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount140:lang(zh-hant) {
          animation-delay: 10500ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount141 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 3525ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount141:lang(zh-hant) {
          animation-delay: 10575ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount142 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 3550ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount142:lang(zh-hant) {
          animation-delay: 10650ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount143 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 3575ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount143:lang(zh-hant) {
          animation-delay: 10725ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount144 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 3600ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount144:lang(zh-hant) {
          animation-delay: 10800ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount145 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 3625ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount145:lang(zh-hant) {
          animation-delay: 10875ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount146 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 3650ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount146:lang(zh-hant) {
          animation-delay: 10950ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount147 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 3675ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount147:lang(zh-hant) {
          animation-delay: 11025ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount148 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 3700ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount148:lang(zh-hant) {
          animation-delay: 11100ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount149 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 3725ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount149:lang(zh-hant) {
          animation-delay: 11175ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount150 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 3750ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount150:lang(zh-hant) {
          animation-delay: 11250ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount151 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 3775ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount151:lang(zh-hant) {
          animation-delay: 11325ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount152 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 3800ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount152:lang(zh-hant) {
          animation-delay: 11400ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount153 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 3825ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount153:lang(zh-hant) {
          animation-delay: 11475ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount154 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 3850ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount154:lang(zh-hant) {
          animation-delay: 11550ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount155 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 3875ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount155:lang(zh-hant) {
          animation-delay: 11625ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount156 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 3900ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount156:lang(zh-hant) {
          animation-delay: 11700ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount157 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 3925ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount157:lang(zh-hant) {
          animation-delay: 11775ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount158 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 3950ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount158:lang(zh-hant) {
          animation-delay: 11850ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount159 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 3975ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount159:lang(zh-hant) {
          animation-delay: 11925ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount160 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 4000ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount160:lang(zh-hant) {
          animation-delay: 12000ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount161 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 4025ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount161:lang(zh-hant) {
          animation-delay: 12075ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount162 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 4050ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount162:lang(zh-hant) {
          animation-delay: 12150ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount163 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 4075ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount163:lang(zh-hant) {
          animation-delay: 12225ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount164 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 4100ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount164:lang(zh-hant) {
          animation-delay: 12300ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount165 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 4125ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount165:lang(zh-hant) {
          animation-delay: 12375ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount166 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 4150ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount166:lang(zh-hant) {
          animation-delay: 12450ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount167 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 4175ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount167:lang(zh-hant) {
          animation-delay: 12525ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount168 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 4200ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount168:lang(zh-hant) {
          animation-delay: 12600ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount169 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 4225ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount169:lang(zh-hant) {
          animation-delay: 12675ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount170 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 4250ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount170:lang(zh-hant) {
          animation-delay: 12750ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount171 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 4275ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount171:lang(zh-hant) {
          animation-delay: 12825ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount172 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 4300ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount172:lang(zh-hant) {
          animation-delay: 12900ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount173 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 4325ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount173:lang(zh-hant) {
          animation-delay: 12975ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount174 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 4350ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount174:lang(zh-hant) {
          animation-delay: 13050ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount175 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 4375ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount175:lang(zh-hant) {
          animation-delay: 13125ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount176 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 4400ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount176:lang(zh-hant) {
          animation-delay: 13200ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount177 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 4425ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount177:lang(zh-hant) {
          animation-delay: 13275ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount178 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 4450ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount178:lang(zh-hant) {
          animation-delay: 13350ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount179 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 4475ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount179:lang(zh-hant) {
          animation-delay: 13425ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount180 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 4500ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount180:lang(zh-hant) {
          animation-delay: 13500ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount181 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 4525ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount181:lang(zh-hant) {
          animation-delay: 13575ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount182 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 4550ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount182:lang(zh-hant) {
          animation-delay: 13650ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount183 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 4575ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount183:lang(zh-hant) {
          animation-delay: 13725ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount184 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 4600ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount184:lang(zh-hant) {
          animation-delay: 13800ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount185 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 4625ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount185:lang(zh-hant) {
          animation-delay: 13875ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount186 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 4650ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount186:lang(zh-hant) {
          animation-delay: 13950ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount187 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 4675ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount187:lang(zh-hant) {
          animation-delay: 14025ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount188 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 4700ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount188:lang(zh-hant) {
          animation-delay: 14100ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount189 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 4725ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount189:lang(zh-hant) {
          animation-delay: 14175ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount190 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 4750ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount190:lang(zh-hant) {
          animation-delay: 14250ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount191 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 4775ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount191:lang(zh-hant) {
          animation-delay: 14325ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount192 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 4800ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount192:lang(zh-hant) {
          animation-delay: 14400ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount193 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 4825ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount193:lang(zh-hant) {
          animation-delay: 14475ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount194 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 4850ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount194:lang(zh-hant) {
          animation-delay: 14550ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount195 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 4875ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount195:lang(zh-hant) {
          animation-delay: 14625ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount196 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 4900ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount196:lang(zh-hant) {
          animation-delay: 14700ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount197 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 4925ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount197:lang(zh-hant) {
          animation-delay: 14775ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount198 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 4950ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount198:lang(zh-hant) {
          animation-delay: 14850ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount199 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 4975ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount199:lang(zh-hant) {
          animation-delay: 14925ms; }
      .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount200 {
        animation: letterSprinkle 1s 500ms;
        animation-delay: 5000ms;
        animation-fill-mode: forwards; }
        .heroContainer .heroContent div.splashSubTitleContainer h5.letterTypingShow span.ani.spanCount200:lang(zh-hant) {
          animation-delay: 15000ms; }

/* for home arrowDown animation */
@keyframes jumpingArrow {
  from {
    transform: translateY(0px);
    opacity: 1; }
  to {
    opacity: 1;
    transform: translateY(3px); } }

/* for top hero image */
@keyframes slideInAnimation {
  from {
    opacity: 0;
    clip-path: inset(0% 0% 0% 90%); }
  to {
    opacity: 1;
    clip-path: inset(0% 0% 0% 0%); } }

/* for general heroContainer */
@keyframes letterSprinkle {
  0% {
    opacity: 0;
    text-shadow: 0px 0px 1px rgba(255, 255, 255, 0.1); }
  66% {
    opacity: 1;
    text-shadow: 0px 0px 20px rgba(255, 255, 255, 0.9); }
  77% {
    opacity: 0.7; }
  100% {
    opacity: 1;
    text-shadow: 0px 0px 20px rgba(255, 255, 255, 0); } }

/* for title text (current noused this animation, leave for backup) */
@keyframes transiformScale {
  from {
    opacity: 0;
    transform: scale(0); }
  to {
    opacity: 1;
    transform: scale(1); } }

/* for animation translateY text */
@keyframes translateYTextAni {
  to {
    opacity: 1;
    transform: translateY(0%); } }

/* for animation inset text */
@keyframes clipPathInsetTextAni {
  to {
    opacity: 1;
    clip-path: inset(0% 0% 0% 0%); } }

/* for title share icon */
@keyframes transiformScaleRotate {
  from {
    opacity: 0;
    transform: scale(0) rotate(-90deg) translateX(0px); }
  to {
    opacity: 1;
    transform: scale(1.2) rotate(180deg) translateX(-10px); } }

/* specific portfolio showcase arrow animation */
@keyframes ArrowRightBounceForward {
  0% {
    transform: translateX(0); }
  40% {
    transform: translateX(20%); }
  60% {
    transform: translateX(20%); }
  100% {
    transform: translateX(-10%); } }

/* for showcase left arrow indicated animation */
@keyframes leftBubbles {
  0% {
    background-position: 90% 0%, 100% 10%, 90% 10%, 85% 15%, 90% 25%, 90% 25%, 90% 40%, 90% 55%, 90% 70%; }
  50% {
    background-position: 80% 0%, 75% 10%, 40% 10%, 5% 5%, 45% 85%, 25% 75%, 60% 100%, 20% 40%, 80% 90%; }
  100% {
    background-position: 0% 0%, 5% 20%, 10% 35%, 3% 55%, -10% 75%, -30% 75%, 30% 120%, 0% 30%, 35% 100%;
    background-size: 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%; } }

@keyframes rightBubbles {
  0% {
    background-position: 10% 100%, 0% 90%, 10% 20%, 15% 15%, 10% 25%, 0% 25%, 10% 40%, 10% 55%, 10% 70%; }
  50% {
    background-position: 20% 0%, 25% 10%, 60% 10%, 95% 5%, 55% 85%, 55% 75%, 80% 100%, 60% 40%, 100% 90%; }
  100% {
    background-position: 100% 0%, 95% -20%, 100% -35%, 95% 55%, 110% 75%, 130% 75%, 130% 120%, 100% 30%, 80% 100%;
    background-size: 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%; } }

/* right menu animation */
@keyframes toggleButtonAniPosition {
  from {
    transform: translateY(0%); }
  to {
    transform: translateY(25%); } }

@keyframes navigateClickableAniPosition {
  from {
    transform: translateY(0%); }
  to {
    transform: translateY(3%); } }

@keyframes breadcrumbAniPosition {
  from {
    transform: translateY(0%); }
  to {
    transform: translateY(15%); } }

/* for content */
@keyframes slideDownAnimation {
  from {
    opacity: 0;
    clip-path: inset(0% 0% 80% 0%); }
  to {
    opacity: 1;
    clip-path: inset(0% 0% 0% 0%); } }

/* end-modules */
/* partials */
.landing .landingContainer {
  left: -14px;
  min-height: 100vh;
  display: flex;
  align-content: center;
  justify-content: center;
  background-color: transparent;
  transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  perspective: 1400px;
  transform-style: preserve-3d; }
  .landing .landingContainer .htmlNoPages {
    position: relative;
    transform: scale(0.6) translateX(-600px); }
    @media (min-width: 768px) {
      .landing .landingContainer .htmlNoPages {
        transform: scale(0.8) translateX(-600px); } }
    @media (min-width: 992px) {
      .landing .landingContainer .htmlNoPages {
        transform: translateX(-600px); } }
  .landing .landingContainer .outerLogoFrame,
  .landing .landingContainer .insideLogoFrame {
    position: absolute;
    top: 0;
    transform-origin: center center;
    transform: rotate(240deg);
    height: 100vh; }

.landing .gwd-img-19vn {
  position: absolute;
  width: 240px;
  height: 240px;
  top: 88px;
  left: 505px;
  transform-style: preserve-3d;
  transform: rotateZ(-720deg); }

.landing [data-gwd-group="gopomo_text"] .gwd-grp-pkem.gwd-img-109u {
  position: absolute;
  transform-style: preserve-3d;
  pointer-events: auto;
  height: 80px;
  width: 80px;
  transform: translate3d(44px, 225px, 0px);
  left: -15.967px;
  opacity: 0;
  top: -208.432px; }

.landing [data-gwd-group="gopomo_text"] .gwd-grp-pkem.gwd-img-9tgm {
  position: absolute;
  width: 80px;
  height: 80px;
  left: 216.033px;
  opacity: 0;
  top: 17.5685px; }

.landing [data-gwd-group="gopomo_text"] {
  width: 550px;
  height: 113.125px; }

.landing .gwd-div-9jje {
  position: absolute;
  left: 322.967px;
  top: 385.432px; }

@keyframes gwd-gen-1yrygwdanimation_gwd-keyframes {
  0% {
    transform: rotateZ(-720deg);
    animation-timing-function: ease; }
  100% {
    transform: rotateZ(0deg);
    animation-timing-function: ease-in-out; } }

.landing .htmlNoPages .gwd-gen-1yrygwdanimation {
  animation: gwd-gen-1yrygwdanimation_gwd-keyframes 3s linear 0s 1 normal forwards; }

.landing [data-gwd-group="gopomo_text"] .gwd-grp-pkem.gwd-img-atmk {
  position: absolute;
  top: 553px;
  left: 478px;
  width: 80px;
  height: 80px; }

.landing [data-gwd-group="gopomo_text"] .gwd-grp-pkem.gwd-img-hmsi {
  opacity: 0;
  left: 16.5685px;
  top: 16.5685px; }

.landing [data-gwd-group="gopomo_text"] .gwd-grp-pkem.gwd-img-ob5b {
  position: absolute;
  width: 80px;
  height: 80px;
  top: 1px;
  left: 16.5685px; }

.landing [data-gwd-group="gopomo_text"] .gwd-grp-pkem.gwd-img-1fu1 {
  transform-style: preserve-3d;
  transform: rotateZ(-720deg);
  top: 16.5685px; }

.landing [data-gwd-group="gopomo_text"] .gwd-grp-pkem.gwd-img-xl7s {
  position: absolute;
  top: 1px;
  width: 80px;
  height: 80px;
  left: 94px; }

.landing [data-gwd-group="gopomo_text"] .gwd-grp-pkem.gwd-img-gnbr {
  opacity: 0;
  transform: rotateZ(134.92deg);
  left: 16.5685px;
  top: 16.5685px; }

.landing [data-gwd-group="gopomo_text"] .gwd-grp-pkem.gwd-img-me5j {
  transform: rotateZ(240deg);
  transform-style: preserve-3d;
  opacity: 0;
  left: 16.5685px;
  top: 16.5685px; }

.landing [data-gwd-group="gopomo_text"] .gwd-grp-pkem.gwd-img-i1hf {
  visibility: visible;
  opacity: 1;
  left: 16.5685px;
  top: 16.9275px; }

.landing [data-gwd-group="gopomo_text"] {
  width: 113.137px;
  height: 113.137px; }

.landing .gwd-div-867k {
  left: 1.26467e-05px;
  top: 1.26467e-05px; }

.landing .gwd-div-183p {
  left: 1.26467e-05px;
  top: 1.26467e-05px; }

.landing .gwd-div-1806 {
  left: 1.26467e-05px;
  top: 1.26467e-05px; }

.landing .gwd-div-xbma {
  left: 1.26467e-05px;
  top: 1.26467e-05px; }

.landing .gwd-div-11lt {
  left: 1.26467e-05px;
  top: 1.26467e-05px; }

.landing .gwd-div-16go {
  left: 1.26467e-05px;
  top: 1.26467e-05px; }

.landing .gwd-div-sdsp {
  left: 1.26467e-05px;
  top: 1.26467e-05px; }

.landing .gwd-div-do1r {
  left: 1.26467e-05px;
  top: 1.26467e-05px; }

.landing .gwd-div-fg9t {
  left: 1.26467e-05px;
  top: 1.26467e-05px; }

.landing .gwd-div-nlwx {
  left: 1.26467e-05px;
  top: 1.26467e-05px; }

.landing .gwd-div-12s6 {
  left: 1.26467e-05px;
  top: 1.26467e-05px; }

.landing .gwd-div-12hu {
  left: 1.26467e-05px;
  top: 1.26467e-05px; }

.landing .gwd-div-xqen {
  left: 1.26467e-05px;
  top: 1.26467e-05px; }

.landing .gwd-div-jknc {
  left: 1.26467e-05px;
  top: 1.26467e-05px; }

.landing .gwd-div-1hgf {
  left: 1.26467e-05px;
  top: 1.26467e-05px; }

.landing .gwd-div-16an {
  left: 1.26467e-05px;
  top: 1.26467e-05px; }

.landing .gwd-div-aawf {
  left: 1.26467e-05px;
  top: 1.26467e-05px; }

.landing .gwd-div-h77f {
  left: 1.26467e-05px;
  top: 1.26467e-05px; }

.landing [data-gwd-group="gopomo_text"] .gwd-grp-pkem.gwd-img-vp03 {
  position: absolute;
  width: 80px;
  height: 80px;
  left: 1.0498e-05px;
  top: 3.2959e-06px;
  visibility: hidden; }

.landing [data-gwd-group="gopomo_text"] {
  width: 592.59px;
  height: 113.641px; }

.landing [data-gwd-group="gopomo_text"] .gwd-grp-pkem.gwd-img-10xi {
  position: absolute;
  transform-style: preserve-3d;
  transform: translate3d(-18px, -17px, 0px) scale3d(0.001, 0.001, 1);
  height: 57px;
  top: 0px;
  left: 2px;
  width: 37px;
  visibility: visible; }

.landing [data-gwd-group="gopomo_text"] .gwd-grp-pkem.gwd-img-1fol {
  position: absolute;
  height: 54.76px;
  width: 34.87px;
  opacity: 0;
  transform-style: preserve-3d;
  visibility: visible;
  overflow: auto;
  left: 39px;
  top: 4.4315px;
  transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1); }

.landing [data-gwd-group="gopomo_text"] .gwd-grp-pkem.gwd-img-eut0 {
  position: absolute;
  height: 70px;
  width: 13.15px;
  opacity: 0;
  transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1);
  left: 62px;
  top: 5.4315px;
  transform-style: preserve-3d; }

.landing .gwd-div-6d76 {
  position: absolute;
  width: 23px;
  height: 124px;
  left: 415px;
  top: 104px; }

.landing [data-gwd-group="gopomo_text"] .gwd-grp-pkem.gwd-img-d1y7 {
  display: block;
  position: absolute;
  transform-style: preserve-3d;
  transform: translate3d(0px, -35px, 0px) scale3d(1, 0.001, 1);
  height: 80px;
  top: 0px;
  left: 0px;
  width: 15px;
  overflow: visible; }

.landing .gwd-div-1wqj {
  position: absolute;
  width: 687px;
  height: 290px;
  left: 0px;
  top: 0px; }

@keyframes gwd-gen-jvj5gwdanimation_gwd-keyframes {
  0% {
    transform: translate3d(0px, -35px, 0px) scale3d(1, 0.001, 1);
    width: 15px;
    animation-timing-function: ease; }
  100% {
    transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1);
    width: 16px;
    animation-timing-function: linear; } }

.landing [data-gwd-group="gopomo_text"] [data-gwd-grp-id="divM"] .gwd-gen-jvj5gwdanimation {
  animation: gwd-gen-jvj5gwdanimation_gwd-keyframes 0.63s linear 1s 1 normal forwards; }

@keyframes gwd-gen-140mgwdanimation_gwd-keyframes {
  0% {
    transform: translate3d(-18px, -17px, 0px) scale3d(0.001, 0.001, 1);
    top: 0px;
    left: 2px;
    height: 57px;
    width: 37px;
    animation-timing-function: ease; }
  100% {
    transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1);
    top: 0px;
    left: 0px;
    height: 57px;
    width: 42px;
    animation-timing-function: linear; } }

.landing [data-gwd-group="gopomo_text"] [data-gwd-grp-id="divM"] .gwd-gen-140mgwdanimation {
  animation: gwd-gen-140mgwdanimation_gwd-keyframes 0.63s linear 1s 1 normal forwards; }

@keyframes gwd-gen-mw5wgwdanimation_gwd-keyframes {
  0% {
    transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1);
    opacity: 0;
    top: 5.4315px;
    left: 62px;
    width: 13.15px;
    height: 70px;
    animation-timing-function: step-end; }
  67.5% {
    transform: translate3d(0px, -40px, 0px) scale3d(1, 0.001, 1);
    opacity: 1;
    top: 0px;
    left: 65px;
    width: 15px;
    height: 80px;
    animation-timing-function: ease; }
  100% {
    transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1);
    opacity: 1;
    top: 0px;
    left: 65px;
    width: 15px;
    height: 80px;
    animation-timing-function: linear; } }

.landing [data-gwd-group="gopomo_text"] [data-gwd-grp-id="divM"] .gwd-gen-mw5wgwdanimation {
  animation: gwd-gen-mw5wgwdanimation_gwd-keyframes 2s linear 1s 1 normal forwards; }

@keyframes gwd-gen-66ybgwdanimation_gwd-keyframes {
  0% {
    opacity: 0;
    transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1);
    left: 39px;
    width: 34.87px;
    height: 54.76px;
    top: 4.4315px;
    animation-timing-function: step-end; }
  46.67% {
    opacity: 1;
    transform: translate3d(-20px, 18px, 0px) scale3d(0.001, 0.001, 1);
    left: 40px;
    width: 42px;
    height: 57px;
    top: 4.4315px;
    animation-timing-function: ease; }
  100% {
    opacity: 1;
    transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1);
    left: 35px;
    width: 42px;
    height: 57px;
    top: 0px;
    animation-timing-function: linear; } }

.landing [data-gwd-group="gopomo_text"] [data-gwd-grp-id="divM"] .gwd-gen-66ybgwdanimation {
  animation: gwd-gen-66ybgwdanimation_gwd-keyframes 1.35s linear 1s 1 normal forwards; }

.landing .gwd-div-c6se {
  left: 1.26467e-05px;
  top: 1.26467e-05px; }

.landing .gwd-div-19yb {
  left: 1.26467e-05px;
  top: 1.26467e-05px; }

.landing .gwd-div-1qsl {
  left: 1.26467e-05px;
  top: 1.26467e-05px; }

.landing .gwd-div-9rb1 {
  left: 1.26467e-05px;
  top: 1.26467e-05px; }

.landing .gwd-div-1lx2 {
  left: 1.26467e-05px;
  top: 1.26467e-05px; }

.landing [data-gwd-group="gopomo_text"] .gwd-grp-pkem.gwd-img-13ds {
  position: absolute;
  width: 66px;
  height: 12px;
  transform-style: preserve-3d;
  left: -32.967px;
  transform: translate3d(0px, 0px, 0px) scale3d(0.001, 1.25, 1);
  top: 20px; }

@keyframes gwd-gen-13sdgwdanimation_gwd-keyframes {
  0% {
    transform: translate3d(0px, 0px, 0px) scale3d(0.001, 1.25, 1);
    height: 12px;
    width: 66px;
    top: 20px;
    animation-timing-function: ease-in; }
  6.67% {
    transform: translate3d(33px, 0px, 0px) scale3d(1, 1.25, 1);
    height: 12px;
    width: 66px;
    top: 20px;
    animation-timing-function: linear; }
  93.33% {
    transform: translate3d(567px, 0px, 0px) scale3d(1, 1.25, 1);
    height: 12px;
    width: 66px;
    top: 20px;
    animation-timing-function: ease-out; }
  100% {
    transform: translate3d(600px, 0px, 0px) scale3d(0.001, 1.25, 1);
    height: 12px;
    width: 66px;
    top: 20px;
    animation-timing-function: linear; } }

.landing [data-gwd-group="gopomo_text"] .gwd-gen-13sdgwdanimation {
  animation: gwd-gen-13sdgwdanimation_gwd-keyframes 1.5s linear 0s 1 normal forwards; }

.landing .gwd-div-1ii0 {
  left: 1.26467e-05px;
  top: 1.26467e-05px; }

.landing .gwd-div-1a95 {
  left: 1.26467e-05px;
  top: 1.26467e-05px; }

.landing .gwd-div-4777 {
  left: 1.26467e-05px;
  top: 1.26467e-05px; }

.landing [data-gwd-group="gopomo_text"] .gwd-grp-pkem.gwd-img-1m6h {
  position: absolute;
  height: 35px;
  width: 13px;
  transform-style: preserve-3d;
  transform: translate3d(0px, -18px, 0px) scale3d(1, 0.001, 1);
  opacity: 0;
  left: 222px;
  top: 57px; }

.landing [data-gwd-group="gopomo_text"] .gwd-grp-pkem.gwd-img-c5zs {
  position: absolute;
  width: 70px;
  height: 70px;
  left: 222px;
  visibility: hidden;
  top: 24px; }

@keyframes gwd-gen-gz67gwdanimation_gwd-keyframes {
  0% {
    transform: translate3d(0px, -18px, 0px) scale3d(1, 0.001, 1);
    opacity: 0;
    width: 13px;
    left: 222px;
    top: 57px;
    animation-timing-function: step-end; }
  45% {
    transform: translate3d(0px, -18px, 0px) scale3d(1, 0.001, 1);
    opacity: 1;
    width: 13px;
    left: 216px;
    top: 63px;
    animation-timing-function: ease; }
  95% {
    left: 216px;
    top: 63px;
    animation-timing-function: ease; }
  100% {
    transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1);
    opacity: 1;
    width: 13px;
    left: 216px;
    top: 63px;
    animation-timing-function: linear; } }

.landing [data-gwd-group="gopomo_text"] .gwd-gen-gz67gwdanimation {
  animation: gwd-gen-gz67gwdanimation_gwd-keyframes 2s linear 0.5s 1 normal forwards; }

.landing [data-gwd-group="gopomo_text"] .gwd-grp-pkem.gwd-img-uzew {
  position: absolute;
  width: 80px;
  height: 80px;
  transform-style: preserve-3d;
  left: 216px;
  transform: rotateZ(-120deg);
  top: 19px; }

.landing [data-gwd-group="gopomo_text"] .gwd-grp-pkem.gwd-img-jfk5 {
  position: absolute;
  width: 80px;
  height: 80px;
  transform: rotateZ(85deg);
  opacity: 0;
  left: 216px;
  top: 19px; }

.landing [data-gwd-group="gopomo_text"] .gwd-grp-pkem.gwd-img-1m8s {
  position: absolute;
  width: 80px;
  height: 80px;
  transform: rotateZ(205deg);
  transform-style: preserve-3d;
  opacity: 0;
  left: 216px;
  top: 19px; }

.landing [data-gwd-group="gopomo_text"] .gwd-grp-pkem.gwd-img-uet3 {
  position: absolute;
  width: 80px;
  height: 80px;
  transform-style: preserve-3d;
  opacity: 1;
  left: 216px;
  transform: rotateZ(-120deg);
  top: 19px; }

@keyframes gwd-gen-1hcqgwdanimation_gwd-keyframes {
  0% {
    transform: rotateZ(-120deg);
    animation-timing-function: ease; }
  100% {
    transform: rotateZ(240deg);
    animation-timing-function: linear; } }

.landing [data-gwd-group="gopomo_text"] .gwd-gen-1hcqgwdanimation {
  animation: gwd-gen-1hcqgwdanimation_gwd-keyframes 2s linear 0.5s 1 normal forwards; }

@keyframes gwd-gen-1mb8gwdanimation_gwd-keyframes {
  0% {
    opacity: 0;
    animation-timing-function: step-end; }
  100% {
    opacity: 1;
    animation-timing-function: linear; } }

@keyframes gwd-gen-1huzgwdanimation_gwd-keyframes {
  0% {
    opacity: 1;
    animation-timing-function: step-end; }
  21.5% {
    opacity: 0;
    animation-timing-function: linear; }
  100% {
    opacity: 0;
    animation-timing-function: linear; } }

@keyframes gwd-gen-c0nagwdanimation_gwd-keyframes {
  0% {
    opacity: 0;
    transform: rotateZ(134.92deg);
    animation-timing-function: step-end; }
  46.25% {
    opacity: 1;
    transform: rotateZ(0deg);
    animation-timing-function: step-end; }
  100% {
    opacity: 0;
    transform: rotateZ(120deg);
    animation-timing-function: linear; } }

.landing [data-gwd-group="gopomo_text"] [data-gwd-grp-id="divO1"] .gwd-gen-1mb8gwdanimation {
  animation: gwd-gen-1mb8gwdanimation_gwd-keyframes 1.98s linear 0s 1 normal forwards; }

@keyframes gwd-gen-j19pgwdanimation_gwd-keyframes {
  0% {
    opacity: 0;
    transform: rotateZ(240deg);
    animation-timing-function: step-end; }
  62.08% {
    opacity: 1;
    transform: rotateZ(120deg);
    animation-timing-function: step-end; }
  100% {
    opacity: 0;
    transform: rotateZ(240deg);
    animation-timing-function: linear; } }

.landing [data-gwd-group="gopomo_text"] .gwd-gen-1huzgwdanimation {
  animation: gwd-gen-1huzgwdanimation_gwd-keyframes 2s linear 0.5s 1 normal forwards; }

.landing [data-gwd-group="gopomo_text"] [data-gwd-grp-id="divO1"] .gwd-gen-c0nagwdanimation {
  animation: gwd-gen-c0nagwdanimation_gwd-keyframes 2.4s linear 0s 1 normal forwards; }

@keyframes gwd-gen-ijf5gwdanimation_gwd-keyframes {
  0% {
    transform: rotateZ(-480deg);
    animation-timing-function: linear; }
  12.51% {
    transform: rotateZ(-480deg);
    animation-timing-function: ease-in-out; }
  100% {
    transform: rotateZ(-90deg);
    animation-timing-function: linear; } }

@keyframes gwd-gen-19ingwdanimation_gwd-keyframes {
  0% {
    opacity: 0;
    transform: rotateZ(85deg);
    animation-timing-function: step-end; }
  21.5% {
    opacity: 1;
    transform: rotateZ(0deg);
    animation-timing-function: linear; }
  100% {
    opacity: 1;
    transform: rotateZ(0deg);
    animation-timing-function: linear; } }

.landing [data-gwd-group="gopomo_text"] [data-gwd-grp-id="divO1"] .gwd-gen-j19pgwdanimation {
  animation: gwd-gen-j19pgwdanimation_gwd-keyframes 2.4s linear 0s 1 normal forwards; }

@keyframes gwd-gen-111jgwdanimation_gwd-keyframes {
  0% {
    opacity: 1;
    transform: rotateZ(-120deg);
    animation-timing-function: linear; }
  27.03% {
    opacity: 1;
    transform: rotateZ(-120deg);
    animation-timing-function: step-end; }
  100% {
    opacity: 0;
    transform: rotateZ(0deg);
    animation-timing-function: linear; } }

.landing [data-gwd-group="gopomo_text"] .gwd-gen-19ingwdanimation {
  animation: gwd-gen-19ingwdanimation_gwd-keyframes 2s linear 0.5s 1 normal forwards; }

.landing [data-gwd-group="gopomo_text"] [data-gwd-grp-id="divO1"] .gwd-gen-ijf5gwdanimation {
  animation: gwd-gen-ijf5gwdanimation_gwd-keyframes 2.4s linear 0s 1 normal forwards; }

@keyframes gwd-gen-nea1gwdanimation_gwd-keyframes {
  0% {
    opacity: 0;
    transform: rotateZ(205deg);
    animation-timing-function: step-end; }
  39% {
    opacity: 1;
    transform: rotateZ(120deg);
    animation-timing-function: linear; }
  100% {
    opacity: 1;
    transform: rotateZ(120deg);
    animation-timing-function: linear; } }

.landing [data-gwd-group="gopomo_text"] [data-gwd-grp-id="divO1"] .gwd-gen-111jgwdanimation {
  animation: gwd-gen-111jgwdanimation_gwd-keyframes 1.11s linear 0s 1 normal forwards; }

.landing [data-gwd-group="gopomo_text"] .gwd-grp-pkem.gwd-img-ctnr {
  transform: rotateZ(0deg);
  transform-style: preserve-3d; }

.landing [data-gwd-group="gopomo_text"] .gwd-gen-nea1gwdanimation {
  animation: gwd-gen-nea1gwdanimation_gwd-keyframes 2s linear 0.5s 1 normal forwards; }

.landing .gwd-div-1bv5 {
  left: 1.26467e-05px;
  top: 1.26467e-05px; }

.landing .gwd-div-1icw {
  left: 1.26467e-05px;
  top: 1.26467e-05px; }

.landing .gwd-div-re4g {
  left: 1.26467e-05px;
  top: 1.26467e-05px; }

.landing .gwd-div-2opp {
  left: 1.26467e-05px;
  top: 1.26467e-05px; }

.landing .gwd-div-192j {
  left: 1.26467e-05px;
  top: 1.26467e-05px; }

.landing .gwd-div-16tj {
  left: 1.26467e-05px;
  top: 1.26467e-05px; }

.landing .gwd-div-134f {
  left: 1.26467e-05px;
  top: 1.26467e-05px; }

.landing .gwd-div-egbo {
  left: 1.26467e-05px;
  top: 1.26467e-05px; }

.landing .gwd-div-nfqe {
  left: 1.26467e-05px;
  top: 1.26467e-05px; }

.landing .gwd-div-e9xt {
  left: 1.26467e-05px;
  top: 1.26467e-05px; }

.landing .gwd-div-wc0y {
  left: 1.26467e-05px;
  top: 1.26467e-05px; }

.landing .gwd-div-lsli {
  left: 1.26467e-05px;
  top: 1.26467e-05px; }

.landing [data-gwd-group="gopomo_text"] .gwd-grp-pkem.gwd-img-1w7q {
  position: absolute;
  width: 80px;
  height: 80px;
  left: 28px;
  transform-style: preserve-3d;
  transform: rotateZ(-360deg);
  top: 17px; }

.landing [data-gwd-group="gopomo_text"] .gwd-grp-pkem.gwd-img-1n9q {
  position: absolute;
  width: 80px;
  height: 80px;
  left: 28px;
  transform-style: preserve-3d;
  transform: rotateZ(182deg);
  opacity: 0;
  top: 17px; }

.landing [data-gwd-group="gopomo_text"] .gwd-grp-pkem.gwd-img-1t00 {
  position: absolute;
  width: 80px;
  height: 80px;
  left: 28px;
  opacity: 1;
  top: 17px; }

@keyframes gwd-gen-um6qgwdanimation_gwd-keyframes {
  0% {
    transform: rotateZ(-360deg);
    animation-timing-function: linear; }
  4.76% {
    transform: rotateZ(-360deg);
    animation-timing-function: ease; }
  100% {
    transform: rotateZ(45deg);
    animation-timing-function: linear; } }

.landing [data-gwd-group="gopomo_text"] .gwd-gen-um6qgwdanimation {
  animation: gwd-gen-um6qgwdanimation_gwd-keyframes 2.1s linear 0s 1 normal forwards; }

@keyframes gwd-gen-1xpfgwdanimation_gwd-keyframes {
  0% {
    opacity: 1;
    animation-timing-function: step-end; }
  100% {
    opacity: 0;
    animation-timing-function: linear; } }

.landing [data-gwd-group="gopomo_text"] .gwd-gen-1xpfgwdanimation {
  animation: gwd-gen-1xpfgwdanimation_gwd-keyframes 0.64s linear 0s 1 normal forwards; }

@keyframes gwd-gen-wpuegwdanimation_gwd-keyframes {
  0% {
    transform: rotateZ(182deg);
    opacity: 0;
    animation-timing-function: step-end; }
  100% {
    transform: rotateZ(270deg);
    opacity: 1;
    animation-timing-function: linear; } }

.landing [data-gwd-group="gopomo_text"] .gwd-gen-wpuegwdanimation {
  animation: gwd-gen-wpuegwdanimation_gwd-keyframes 0.87s linear 0s 1 normal forwards; }

.landing [data-gwd-group="gopomo_text"] .gwd-grp-pkem.gwd-img-12m7 {
  position: absolute;
  height: 15px;
  width: 31px;
  left: 77px;
  opacity: 0;
  transform-style: preserve-3d;
  transform: scale3d(1, 1, 1);
  top: 57px; }

.landing [data-gwd-group="gopomo_text"] .gwd-grp-pkem.gwd-img-166v {
  position: absolute;
  height: 40px;
  width: 15px;
  left: 93px;
  opacity: 0;
  transform-style: preserve-3d;
  transform: scale3d(1, 1, 1);
  top: 57px; }

@keyframes gwd-gen-yfkqgwdanimation_gwd-keyframes {
  0% {
    transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1);
    left: 77px;
    opacity: 0;
    animation-timing-function: step-end; }
  41.43% {
    transform: translate3d(2px, 0px, 0px) scale3d(0.001, 1, 1);
    left: 77px;
    opacity: 1;
    animation-timing-function: ease; }
  100% {
    transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1);
    left: 77px;
    opacity: 1;
    animation-timing-function: linear; } }

.landing [data-gwd-group="gopomo_text"] .gwd-gen-yfkqgwdanimation {
  animation: gwd-gen-yfkqgwdanimation_gwd-keyframes 2.1s linear 0s 1 normal forwards;
  --gwd-timeline: ; }

@keyframes gwd-gen-188ogwdanimation_gwd-keyframes {
  0% {
    opacity: 0;
    transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1);
    animation-timing-function: step-end; }
  41.43% {
    opacity: 1;
    transform: translate3d(0px, -19px, 0px) scale3d(1, 0.001, 1);
    animation-timing-function: ease; }
  100% {
    opacity: 1;
    transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1);
    animation-timing-function: linear; } }

.landing [data-gwd-group="gopomo_text"] .gwd-gen-188ogwdanimation {
  animation: gwd-gen-188ogwdanimation_gwd-keyframes 2.1s linear 0s 1 normal forwards; }

.landing .gwd-div-1upc {
  left: 8.85269e-05px;
  top: 8.85269e-05px; }

.landing .gwd-div-jdnx {
  left: 8.85269e-05px;
  top: 8.85269e-05px; }

.landing .gwd-div-muwt {
  left: 8.85269e-05px;
  top: 8.85269e-05px; }

.landing .gwd-div-hziu {
  position: absolute;
  left: 308px;
  top: 64px;
  width: 80px;
  height: 80px; }

.landing .gwd-div-1m38 {
  position: absolute;
  left: 105.465px;
  width: 113.137px;
  height: 113.137px;
  top: 0px; }

@keyframes gwd-gen-kiwygwdanimation_gwd-keyframes {
  0% {
    opacity: 0;
    animation-timing-function: step-end; }
  100% {
    opacity: 1;
    animation-timing-function: linear; } }

.landing [data-gwd-group="gopomo_text"] [data-gwd-grp-id="divO2"] .gwd-gen-kiwygwdanimation {
  animation: gwd-gen-kiwygwdanimation_gwd-keyframes 2.1701s linear 0s 1 normal forwards; }

@keyframes gwd-gen-ypthgwdanimation_gwd-keyframes {
  0% {
    opacity: 0;
    transform: rotateZ(134.92deg);
    animation-timing-function: step-end; }
  52.76% {
    opacity: 1;
    transform: rotateZ(0deg);
    animation-timing-function: step-end; }
  100% {
    opacity: 0;
    transform: rotateZ(120deg);
    animation-timing-function: linear; } }

.landing [data-gwd-group="gopomo_text"] [data-gwd-grp-id="divO2"] .gwd-gen-ypthgwdanimation {
  animation: gwd-gen-ypthgwdanimation_gwd-keyframes 2.9001s linear 0s 1 normal forwards; }

@keyframes gwd-gen-x9wvgwdanimation_gwd-keyframes {
  0% {
    opacity: 0;
    transform: rotateZ(240deg);
    animation-timing-function: step-end; }
  63.79% {
    opacity: 1;
    transform: rotateZ(120deg);
    animation-timing-function: step-end; }
  100% {
    opacity: 0;
    transform: rotateZ(240deg);
    animation-timing-function: linear; } }

.landing [data-gwd-group="gopomo_text"] [data-gwd-grp-id="divO2"] .gwd-gen-x9wvgwdanimation {
  animation: gwd-gen-x9wvgwdanimation_gwd-keyframes 2.9001s linear 0s 1 normal forwards; }

@keyframes gwd-gen-13gagwdanimation_gwd-keyframes {
  0% {
    transform: rotateZ(-480deg);
    animation-timing-function: linear; }
  27.59% {
    transform: rotateZ(-480deg);
    animation-timing-function: ease-in-out; }
  100% {
    transform: rotateZ(0deg);
    animation-timing-function: linear; } }

.landing [data-gwd-group="gopomo_text"] [data-gwd-grp-id="divO2"] .gwd-gen-13gagwdanimation {
  animation: gwd-gen-13gagwdanimation_gwd-keyframes 2.9001s linear 0s 1 normal forwards; }

@keyframes gwd-gen-151bgwdanimation_gwd-keyframes {
  0% {
    opacity: 1;
    transform: rotateZ(-120deg);
    animation-timing-function: linear; }
  40% {
    opacity: 1;
    transform: rotateZ(-120deg);
    animation-timing-function: step-end; }
  76.51% {
    opacity: 0;
    transform: rotateZ(0deg);
    animation-timing-function: linear; }
  100% {
    opacity: 0;
    transform: rotateZ(0deg);
    animation-timing-function: linear; } }

.landing [data-gwd-group="gopomo_text"] [data-gwd-grp-id="divO2"] .gwd-gen-151bgwdanimation {
  animation: gwd-gen-151bgwdanimation_gwd-keyframes 2.0001s linear 0s 1 normal forwards; }

.landing [data-gwd-group="gopomo_text"] .gwd-grp-pkem.gwd-div-jap2 {
  height: 113.125px;
  position: absolute;
  width: 113.125px;
  left: 291.465px;
  top: 47px; }

@keyframes gwd-gen-qycugwdanimation_gwd-keyframes {
  0% {
    opacity: 0;
    animation-timing-function: step-end; }
  100% {
    opacity: 1;
    animation-timing-function: linear; } }

.landing [data-gwd-group="gopomo_text"] [data-gwd-grp-id="divO3"] .gwd-gen-qycugwdanimation {
  animation: gwd-gen-qycugwdanimation_gwd-keyframes 2.4102s linear 0s 1 normal forwards; }

@keyframes gwd-gen-1kaqgwdanimation_gwd-keyframes {
  0% {
    opacity: 0;
    transform: rotateZ(134.92deg);
    animation-timing-function: step-end; }
  56.67% {
    opacity: 1;
    transform: rotateZ(0deg);
    animation-timing-function: step-end; }
  100% {
    opacity: 0;
    transform: rotateZ(120deg);
    animation-timing-function: linear; } }

.landing [data-gwd-group="gopomo_text"] [data-gwd-grp-id="divO3"] .gwd-gen-1kaqgwdanimation {
  animation: gwd-gen-1kaqgwdanimation_gwd-keyframes 3.3002s linear 0s 1 normal forwards; }

@keyframes gwd-gen-1lfmgwdanimation_gwd-keyframes {
  0% {
    opacity: 0;
    transform: rotateZ(240deg);
    animation-timing-function: step-end; }
  65.15% {
    opacity: 1;
    transform: rotateZ(120deg);
    animation-timing-function: step-end; }
  100% {
    opacity: 0;
    transform: rotateZ(240deg);
    animation-timing-function: linear; } }

.landing [data-gwd-group="gopomo_text"] [data-gwd-grp-id="divO3"] .gwd-gen-1lfmgwdanimation {
  animation: gwd-gen-1lfmgwdanimation_gwd-keyframes 3.3002s linear 0s 1 normal forwards; }

@keyframes gwd-gen-1npbgwdanimation_gwd-keyframes {
  0% {
    transform: rotateZ(-480deg);
    animation-timing-function: linear; }
  36.37% {
    transform: rotateZ(-480deg);
    animation-timing-function: ease-in-out; }
  100% {
    transform: rotateZ(90deg);
    animation-timing-function: linear; } }

.landing [data-gwd-group="gopomo_text"] [data-gwd-grp-id="divO3"] .gwd-gen-1npbgwdanimation {
  animation: gwd-gen-1npbgwdanimation_gwd-keyframes 3.3002s linear 0s 1 normal forwards; }

@keyframes gwd-gen-1mhkgwdanimation_gwd-keyframes {
  0% {
    opacity: 1;
    transform: rotateZ(-120deg);
    animation-timing-function: linear; }
  64.16% {
    opacity: 1;
    transform: rotateZ(-120deg);
    animation-timing-function: step-end; }
  100% {
    opacity: 0;
    transform: rotateZ(0deg);
    animation-timing-function: linear; } }

.landing [data-gwd-group="gopomo_text"] [data-gwd-grp-id="divO3"] .gwd-gen-1mhkgwdanimation {
  animation: gwd-gen-1mhkgwdanimation_gwd-keyframes 1.8702s linear 0s 1 normal forwards; }

.landing [data-gwd-group="gopomo_text"] .gwd-grp-pkem.gwd-div-6uxd {
  left: 479.465px;
  top: 0px; }

.landing [data-gwd-group="gopomo_text"] .gwd-grp-pkem.gwd-img-ecoc {
  transform: rotateZ(-120deg); }

.landing [data-gwd-group="gopomo_text"] .gwd-grp-pkem.gwd-img-7h75 {
  transform: rotateZ(-480deg); }

.landing [data-gwd-group="gopomo_text"] .gwd-grp-pkem.gwd-img-1io8 {
  transform: rotateZ(-120deg); }

.landing [data-gwd-group="gopomo_text"] .gwd-grp-pkem.gwd-img-5wfj {
  transform: rotateZ(-480deg); }

.landing [data-gwd-group="gopomo_text"] .gwd-grp-pkem.gwd-img-16rw {
  transform: rotateZ(-480deg); }

.landing [data-gwd-group="gopomo_text"] .gwd-grp-pkem.gwd-img-18tt {
  transform: rotateZ(-120deg); }

.landing .gwd-div-9c0q {
  position: absolute;
  left: 403.033px;
  top: 0px;
  width: 80px;
  height: 144.569px; }

.landing .gwd-div-pvpj {
  position: absolute;
  left: 403.033px;
  width: 80px;
  height: 80px;
  top: 17.5685px; }

.landing [data-gwd-group="gopomo_text"] .gwd-grp-pkem.gwd-div-wmi7 {
  top: 0px; }

.header {
  /* --- for top Menu --- */
  /* --- for right sticky Menu --- */ }
  @media (min-width: 768px) {
    .header div.topMenu ul {
      height: 93px; } }
  @media (min-width: 992px) {
    .header div.topMenu ul {
      height: 109px; } }
  @media (min-width: 1200px) {
    .header div.topMenu ul {
      height: 157px; } }
  .header div.topMenu ul li a {
    font-family: "Didot", Helvetica, sans-serif;
    font-weight: bold;
    line-height: 29px;
    color: #AAAAA0; }
  .header div.topMenu ul li.active a {
    color: #333333;
    border-bottom: 1px solid #333333; }
  .header div.topMenu ul .headerLogoAnimation {
    transform: rotate(0deg);
    transition-timing-function: linear;
    transition: all 3s; }
  .header div.topMenu ul:hover .headerLogoAnimation {
    transform: rotate(360deg); }
  .header div.topMenu ul .nav-item.navItemLanguage > .nav-link.active {
    color: #333333; }
  .header div.rightMenu {
    /* general style */
    /* for active, clickable ul */
    /* for breadcrumbs (show information) */ }
    .header div.rightMenu div.topLineContainer {
      overflow: hidden;
      width: 1px;
      height: 110px;
      margin-top: 5vh;
      background: #AAAAA0;
      position: relative;
      display: none; }
      @media (min-width: 768px) {
        .header div.rightMenu div.topLineContainer {
          display: block; } }
      .header div.rightMenu div.topLineContainer div.topLine {
        background: black;
        position: absolute;
        height: 110px;
        width: 1px;
        transform: translateY(0%);
        animation: topLinePosition 3s cubic-bezier(0.455, 0.03, 0.515, 0.955) infinite; }

@keyframes topLinePosition {
  from {
    transform: translateY(-100%); }
  to {
    transform: translateY(100%); } }
    .header div.rightMenu nav.navBarContainer {
      position: fixed;
      top: 0;
      right: 0;
      left: auto;
      z-index: 1030; }
      @media (min-width: 768px) {
        .header div.rightMenu nav.navBarContainer {
          left: calc(100vw - 40px - 16px*2 - 50px); } }
    .header div.rightMenu ul li a {
      font-size: 14px;
      color: #AAAAA0; }
    .header div.rightMenu ul li.active a {
      color: #333333; }
    .header div.rightMenu button.togglerButton {
      border-radius: 50%;
      min-width: 40px;
      min-height: 40px;
      /* for infinite animation */
      animation-name: toggleButtonAniPosition;
      animation-duration: 2000ms;
      animation-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
      animation-iteration-count: infinite;
      animation-direction: alternate;
      /* reset default style */ }
      .header div.rightMenu button.togglerButton div.triangleContainer {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        display: flex;
        border-width: 1px;
        border-style: solid;
        border-color: black;
        background: black;
        /* for open/close triangle */
        transition: all 150ms linear; }
        .header div.rightMenu button.togglerButton div.triangleContainer span {
          display: block; }
          .header div.rightMenu button.togglerButton div.triangleContainer span.triangle {
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 5px 0 5px 7px;
            border-color: transparent transparent transparent white; }
      .header div.rightMenu button.togglerButton.openToggle {
        border-color: #AAAAA0; }
        .header div.rightMenu button.togglerButton.openToggle div.triangleContainer {
          transform: rotate(90deg);
          background: white;
          border-color: black; }
          .header div.rightMenu button.togglerButton.openToggle div.triangleContainer span.triangle {
            border-color: transparent transparent transparent black; }
      .header div.rightMenu button.togglerButton.closeToggle div.triangleContainer {
        transform: rotate(0deg); }
      .header div.rightMenu button.togglerButton:focus, .header div.rightMenu button.togglerButton:active, .header div.rightMenu button.togglerButton:hover {
        background: transparent;
        outline: none;
        box-shadow: unset; }
      .header div.rightMenu button.togglerButton.btn-outline-secondary:active:active, .header div.rightMenu button.togglerButton.btn-outline-secondary:active:focus {
        box-shadow: unset;
        background: transparent; }
    .header div.rightMenu div.navigateClickable {
      /* for infinite animation */
      animation-name: navigateClickableAniPosition;
      animation-duration: 2000ms;
      animation-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
      animation-iteration-count: infinite;
      animation-direction: alternate; }
      .header div.rightMenu div.navigateClickable > ul {
        background-color: rgba(255, 255, 255, 0.7);
        border-radius: 8px; }
        .header div.rightMenu div.navigateClickable > ul li {
          color: #AAAAA0; }
      .header div.rightMenu div.navigateClickable .nav-item.navItemLanguage > .nav-link.active {
        color: #333333; }
    .header div.rightMenu div.breadcrumbInfo {
      /* for infinite animation */
      animation-name: breadcrumbAniPosition;
      animation-duration: 2000ms;
      animation-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
      animation-iteration-count: infinite;
      animation-direction: alternate;
      animation-delay: 500ms;
      cursor: pointer;
      height: 55px;
      display: flex; }
      .header div.rightMenu div.breadcrumbInfo:not(.show) {
        display: none; }
      .header div.rightMenu div.breadcrumbInfo ul {
        transform: rotate(90deg); }

.footer .footerContainer {
  background: #333333;
  height: 40px;
  font-family: "Didot", Helvetica, sans-serif; }

.portfolioNavIndicator .toggleContainer {
  pointer-events: none;
  position: fixed;
  bottom: 1%;
  z-index: 3;
  /* translateX shift left amount of total `thumbnailWidth + scrollBarWidth` */
  transform: translateX(-258px);
  transition: all 0.5s; }
  .portfolioNavIndicator .toggleContainer .collapseContainer button {
    border: 0;
    color: #666666; }
    .portfolioNavIndicator .toggleContainer .collapseContainer button * {
      pointer-events: fill;
      transition: all 500ms linear; }
    .portfolioNavIndicator .toggleContainer .collapseContainer button p {
      transform: rotate(90deg) translate(15px, 20px);
      transform-origin: left;
      opacity: 1;
      font-family: "GothamNarrow", Helvetica, sans-serif; }
    .portfolioNavIndicator .toggleContainer .collapseContainer button img {
      transform: rotate(0deg); }
    .portfolioNavIndicator .toggleContainer .collapseContainer button:hover, .portfolioNavIndicator .toggleContainer .collapseContainer button:active, .portfolioNavIndicator .toggleContainer .collapseContainer button:focus, .portfolioNavIndicator .toggleContainer .collapseContainer button:active:focus {
      background: transparent;
      box-shadow: unset; }
  .portfolioNavIndicator .toggleContainer .extensionContainer {
    pointer-events: fill; }
    .portfolioNavIndicator .toggleContainer .extensionContainer .circleHalo {
      width: 12px;
      height: 12px;
      border-radius: 100%;
      background: #FFE100;
      /* animation definition */
      animation-name: haloAnimation;
      animation-timing-function: ease-in-out;
      animation-iteration-count: infinite;
      animation-duration: 1s;
      animation-direction: alternate; }

@keyframes haloAnimation {
  from {
    background: #FFE100;
    box-shadow: 0 0 20px 2px #FFE100; }
  to {
    background: #FFE100;
    box-shadow: 0 0 20px 6px #FFE100; } }
    .portfolioNavIndicator .toggleContainer .extensionContainer .triangleDown {
      cursor: pointer;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 17px 8.5px 0 8.5px;
      border-color: #FFE100 transparent transparent transparent; }
      .portfolioNavIndicator .toggleContainer .extensionContainer .triangleDown.rotateToUp {
        transform: rotate(180deg); }
    .portfolioNavIndicator .toggleContainer .extensionContainer .portfoliosContainer {
      max-height: 450px;
      overflow-x: hidden;
      overflow-y: scroll; }
      .portfolioNavIndicator .toggleContainer .extensionContainer .portfoliosContainer p {
        letter-spacing: 1px; }
      .portfolioNavIndicator .toggleContainer .extensionContainer .portfoliosContainer::-webkit-scrollbar {
        /* whole container */
        width: 8px; }
      .portfolioNavIndicator .toggleContainer .extensionContainer .portfoliosContainer::-webkit-scrollbar-track {
        /* the scroll bar backgournd */
        border-radius: 4px;
        background: transparent; }
      .portfolioNavIndicator .toggleContainer .extensionContainer .portfoliosContainer::-webkit-scrollbar-thumb {
        /* the scroll bar */
        background: rgba(0, 0, 0, 0.5);
        border-radius: 4px; }
      .portfolioNavIndicator .toggleContainer .extensionContainer .portfoliosContainer img.whitelogo {
        display: none;
        width: 100px; }
      .portfolioNavIndicator .toggleContainer .extensionContainer .portfoliosContainer a:hover {
        text-decoration: none; }
        .portfolioNavIndicator .toggleContainer .extensionContainer .portfoliosContainer a:hover P {
          display: none; }
        .portfolioNavIndicator .toggleContainer .extensionContainer .portfoliosContainer a:hover img.whitelogo {
          display: block; }
      .portfolioNavIndicator .toggleContainer .extensionContainer .portfoliosContainer .portfolioSection {
        height: 150px;
        width: 250px;
        background-repeat: no-repeat;
        background-size: cover; }
        .portfolioNavIndicator .toggleContainer .extensionContainer .portfoliosContainer .portfolioSection .grayCover {
          height: 100%;
          opacity: 0.8;
          background-color: #666666;
          color: white;
          font-family: "Lekton";
          letter-spacing: 5px; }
  .portfolioNavIndicator .toggleContainer.onShowTiny {
    transform: translateX(-240px); }
  .portfolioNavIndicator .toggleContainer.onExtension {
    transform: translateX(0%); }
    .portfolioNavIndicator .toggleContainer.onExtension div.collapseContainer button p {
      transform: rotate(0deg) translate(0px, 0px);
      opacity: 0; }
    .portfolioNavIndicator .toggleContainer.onExtension div.collapseContainer button img {
      transform: rotate(180deg); }

.home {
  /* about section */ }
  .home .viewPort {
    max-height: calc(50vh);
    overflow: hidden; }
    @media (min-width: 576px) {
      .home .viewPort {
        max-height: calc(90vh); } }
    @media (min-width: 768px) {
      .home .viewPort {
        max-height: calc(100vh - 93px); } }
    @media (min-width: 992px) {
      .home .viewPort {
        max-height: calc(100vh - 109px); } }
    @media (min-width: 1200px) {
      .home .viewPort {
        max-height: calc(100vh - 157px); } }
    .home .viewPort video {
      position: relative;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      display: block;
      padding: 0;
      overflow: hidden;
      min-height: 100vh;
      min-width: 100vw; }
    .home .viewPort div.sloganContainer {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      background-color: rgba(0, 0, 0, 0.75); }
      .home .viewPort div.sloganContainer p.sloganMain {
        font-family: "GothamNarrow", Helvetica, sans-serif;
        font-size: 3em; }
        @media (min-width: 576px) {
          .home .viewPort div.sloganContainer p.sloganMain {
            font-size: 6em; } }
      .home .viewPort div.sloganContainer p.sloganSub {
        font-family: "GothamNarrow", Helvetica, sans-serif; }
        @media (min-width: 576px) {
          .home .viewPort div.sloganContainer p.sloganSub {
            font-size: 2em; } }
      .home .viewPort div.sloganContainer .typingCursor {
        color: white; }
      .home .viewPort div.sloganContainer a.arrowDown,
      .home .viewPort div.sloganContainer button.arrowDown {
        cursor: pointer;
        transition: all 2000ms;
        opacity: 1;
        animation-name: jumpingArrow;
        animation-iteration-count: infinite;
        animation-duration: 500ms;
        animation-timing-function: linear;
        animation-direction: alternate;
        color: white; }
        @media (min-width: 576px) {
          .home .viewPort div.sloganContainer a.arrowDown,
          .home .viewPort div.sloganContainer button.arrowDown {
            font-size: 1.5em; } }
        @media (min-width: 768px) {
          .home .viewPort div.sloganContainer a.arrowDown,
          .home .viewPort div.sloganContainer button.arrowDown {
            font-size: 2em; } }
        .home .viewPort div.sloganContainer a.arrowDown.hidden,
        .home .viewPort div.sloganContainer button.arrowDown.hidden {
          color: transparent; }
  .home .about .mainSections .aboutSections h3.title {
    border-bottom: 1px solid #333333;
    width: fit-content;
    letter-spacing: 3px; }
  .home .about .mainSections .aboutSections h5.subTitle {
    font-size: 18px; }
  .home .about .mainSections .aboutSections * {
    color: #333333; }
  .home .about .mainSections .aboutSections h2 {
    position: relative;
    width: fit-content; }
    .home .about .mainSections .aboutSections h2::before {
      position: absolute;
      left: 0;
      bottom: 0;
      content: ' ';
      width: 100%;
      height: 10px;
      opacity: 0.5;
      background-color: #FFE100; }
  .home .about .mainSections div.newsSection {
    color: #333333; }
    .home .about .mainSections div.newsSection h3.title {
      border-bottom: 1px solid #333333;
      width: fit-content;
      letter-spacing: 3px; }
    .home .about .mainSections div.newsSection li.newsItem {
      background: transparent;
      border: 0px; }
      .home .about .mainSections div.newsSection li.newsItem a {
        color: inherit; }
        .home .about .mainSections div.newsSection li.newsItem a:hover {
          text-decoration: none; }
        .home .about .mainSections div.newsSection li.newsItem a.disabled {
          pointer-events: none; }
  .home .about .mainSections div.portfolioCitiesSection div.triangleLeft {
    cursor: pointer;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 8.5px 17px 8.5px 0px;
    border-color: transparent #FFE100 transparent transparent; }
    .home .about .mainSections div.portfolioCitiesSection div.triangleLeft.rotateToRight {
      transform: rotate(180deg); }
  .home .about .mainSections div.portfolioCitiesSection div.portfoliosCitiesWrapper a.portfolioContainer {
    border-radius: 50%;
    overflow: hidden;
    max-width: calc(100vw - 30px);
    max-height: calc(100vw - 30px);
    width: 370px;
    height: 370px;
    transition: all 500ms ease;
    /* other default portfolioItem to show/hide */
    /* hiding ani */
    /* showing ani */ }
    .home .about .mainSections div.portfolioCitiesSection div.portfoliosCitiesWrapper a.portfolioContainer.portfolioHide {
      position: absolute;
      left: 0;
      z-index: -1;
      transform: scale(0); }
    .home .about .mainSections div.portfolioCitiesSection div.portfoliosCitiesWrapper a.portfolioContainer.hidingPortfolio {
      transform: scale(0); }
    .home .about .mainSections div.portfolioCitiesSection div.portfoliosCitiesWrapper a.portfolioContainer.showingPortfolio {
      transform: scale(1); }
    .home .about .mainSections div.portfolioCitiesSection div.portfoliosCitiesWrapper a.portfolioContainer div.heroImgContainer {
      width: 100%;
      height: 100%; }
      .home .about .mainSections div.portfolioCitiesSection div.portfoliosCitiesWrapper a.portfolioContainer div.heroImgContainer div.heroImgWrapper {
        height: 100%; }
        .home .about .mainSections div.portfolioCitiesSection div.portfoliosCitiesWrapper a.portfolioContainer div.heroImgContainer div.heroImgWrapper img.heroImage {
          height: 100%;
          width: auto; }
    .home .about .mainSections div.portfolioCitiesSection div.portfoliosCitiesWrapper a.portfolioContainer div.contentContainer {
      top: 0;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background-color: rgba(255, 225, 0, 0.2);
      color: #333333;
      /* hover ani */
      transform: scale(0);
      transition: all 500ms ease; }
      .home .about .mainSections div.portfolioCitiesSection div.portfoliosCitiesWrapper a.portfolioContainer div.contentContainer div.contentsWrapper {
        width: calc(100% - 50px);
        height: calc(100% - 50px);
        border-radius: 50%;
        background-color: rgba(255, 255, 255, 0.6);
        /* hover ani */
        transform: scale(0);
        transition: all 500ms ease;
        transition-delay: 200ms; }
        .home .about .mainSections div.portfolioCitiesSection div.portfoliosCitiesWrapper a.portfolioContainer div.contentContainer div.contentsWrapper img.cityImage {
          width: auto;
          max-height: calc(100% - 50px); }
    .home .about .mainSections div.portfolioCitiesSection div.portfoliosCitiesWrapper a.portfolioContainer:hover div.contentContainer {
      transform: scale(1); }
      .home .about .mainSections div.portfolioCitiesSection div.portfoliosCitiesWrapper a.portfolioContainer:hover div.contentContainer div.contentsWrapper {
        transform: scale(1); }

.portfolio .mainSections .portfolioSection a {
  color: #333333;
  text-decoration: none;
  position: relative;
  /* card outer container for perspective */ }
  .portfolio .mainSections .portfolioSection a div.cardFrame {
    perspective: 30px;
    /* moving area key atttribute */ }
    .portfolio .mainSections .portfolioSection a div.cardFrame div.transformTargetCard {
      transition: all 300ms linear;
      transform-style: preserve-3d;
      box-shadow: 2px 2px 50px rgba(0, 0, 0, 0.2);
      padding: 0px; }
      @media (min-width: 576px) {
        .portfolio .mainSections .portfolioSection a div.cardFrame div.transformTargetCard {
          padding: 1.5rem; } }
      @media (min-width: 992px) {
        .portfolio .mainSections .portfolioSection a div.cardFrame div.transformTargetCard {
          padding: 1.25rem; } }
  .portfolio .mainSections .portfolioSection a div.card.card-body {
    border: 10px solid #FFE100; }
    .portfolio .mainSections .portfolioSection a div.card.card-body h5,
    .portfolio .mainSections .portfolioSection a div.card.card-body h6 {
      font-family: "GothamNarrow", Helvetica, sans-serif; }
    .portfolio .mainSections .portfolioSection a div.card.card-body h5 {
      color: #333333;
      position: relative;
      font-size: 1.5em;
      width: fit-content; }
      .portfolio .mainSections .portfolioSection a div.card.card-body h5 span {
        z-index: 2; }
      .portfolio .mainSections .portfolioSection a div.card.card-body h5 div.textDecoration {
        border-radius: 3px;
        z-index: -1;
        width: 120%;
        height: 10px;
        background-color: #FFE100;
        opacity: 0.5;
        position: absolute;
        bottom: 0px;
        left: calc((100% - 120%)/2); }
    .portfolio .mainSections .portfolioSection a div.card.card-body p {
      white-space: pre-wrap; }

@media (min-width: 992px) {
  .portfolio .mainSections .portfolioSection:not(:first-child) {
    margin-top: -15%; } }

.specificPortfolio .heroContainer {
  color: #333333;
  min-height: 100vh; }
  .specificPortfolio .heroContainer .heroContent {
    min-height: 100vh; }
  .specificPortfolio .heroContainer h1,
  .specificPortfolio .heroContainer h2 {
    font-family: "Lekton", Helvetica, sans-serif;
    font-style: italic;
    font-weight: normal; }
  .specificPortfolio .heroContainer div.portfolioTextContainer {
    overflow: hidden; }
    .specificPortfolio .heroContainer div.portfolioTextContainer h1.transiformTrasnlateYAni {
      /* animation */
      transform: translateY(110%);
      opacity: 0;
      animation-delay: 200ms;
      animation-name: translateYTextAni;
      animation-duration: 1000ms;
      animation-fill-mode: forwards; }
  .specificPortfolio .heroContainer div.portfolioNameContainer {
    overflow: hidden; }
    .specificPortfolio .heroContainer div.portfolioNameContainer h2.transiformTrasnlateYAni {
      /* animation */
      transform: translateY(-110%);
      opacity: 0;
      animation-delay: 200ms;
      animation-name: translateYTextAni;
      animation-duration: 1000ms;
      animation-fill-mode: forwards; }
  .specificPortfolio .heroContainer div.imgWrapper {
    background: #EAEAEA;
    /* centerHeroImage animation */ }
    .specificPortfolio .heroContainer div.imgWrapper img.centerHeroImage {
      animation: slideInAnimation;
      animation-timing-function: ease-in-out;
      animation-duration: 1s; }
  .specificPortfolio .heroContainer img.arrowDown {
    height: 45%; }

.specificPortfolio .introductionSection {
  /* animation */ }
  .specificPortfolio .introductionSection img.logoImage {
    width: 120px;
    /* hideLogo properties */
    opacity: 0;
    clip-path: inset(0% 0% 0% 90%); }
    .specificPortfolio .introductionSection img.logoImage.showLogo {
      animation: slideInAnimation;
      animation-timing-function: ease-in-out;
      animation-duration: 1s;
      animation-fill-mode: forwards; }
  .specificPortfolio .introductionSection h4 {
    font-family: "Lekton", Helvetica, sans-serif;
    font-style: italic;
    /* for animation default setting */
    width: fit-content;
    clip-path: inset(0% 100% 0% 0%);
    opacity: 0; }
    .specificPortfolio .introductionSection h4.clipPathAniIn {
      /* animation */
      animation-name: clipPathInsetTextAni;
      animation-duration: 1200ms;
      animation-fill-mode: forwards; }
  .specificPortfolio .introductionSection h2 {
    font-family: "Lekton", Helvetica, sans-serif;
    font-weight: bold;
    letter-spacing: 2px;
    /* for animation default setting */
    clip-path: inset(0% 100% 0% 0%);
    opacity: 0;
    /* for herf span */ }
    .specificPortfolio .introductionSection h2.clipPathAniIn {
      /* animation */
      animation-name: clipPathInsetTextAni;
      animation-duration: 1500ms;
      animation-fill-mode: forwards; }
    .specificPortfolio .introductionSection h2 > span {
      display: inline-block;
      transform: scale(0);
      opacity: 0; }
      .specificPortfolio .introductionSection h2 > span.linkIcon {
        width: 25px;
        animation: transiformScaleRotate 1s 1000ms;
        animation-delay: 1000ms;
        animation-fill-mode: forwards;
        transform-origin: 50% 50%; }
    .specificPortfolio .introductionSection h2 span.linkIcon svg {
      width: 100%; }
      .specificPortfolio .introductionSection h2 span.linkIcon svg path {
        fill: black; }
      .specificPortfolio .introductionSection h2 span.linkIcon svg polygon {
        stroke: black; }
    .specificPortfolio .introductionSection h2 span.linkIcon:hover svg path,
    .specificPortfolio .introductionSection h2 span.linkIcon:hover svg polygon {
      fill: #FFE100; }
    .specificPortfolio .introductionSection h2 span.linkIcon:hover svg polygon {
      stroke: #FFE100; }
  .specificPortfolio .introductionSection p {
    white-space: pre-wrap;
    font-size: 1.1em; }
  .specificPortfolio .introductionSection small {
    font-size: 1em;
    font-family: "Lekton", Helvetica, sans-serif; }
  .specificPortfolio .introductionSection div.overflowHidden {
    overflow: hidden; }
    .specificPortfolio .introductionSection div.overflowHidden #descriptionContainer {
      transition: 1s cubic-bezier(0.25, 0.46, 0.45, 0.94); }
      .specificPortfolio .introductionSection div.overflowHidden #descriptionContainer.hide {
        transform: translateY(100%); }
      .specificPortfolio .introductionSection div.overflowHidden #descriptionContainer.show {
        transform: translateY(0%); }
    .specificPortfolio .introductionSection div.overflowHidden #imageContainer {
      transition: 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) 200ms; }
      .specificPortfolio .introductionSection div.overflowHidden #imageContainer.hide {
        transform: translateY(100%); }
      .specificPortfolio .introductionSection div.overflowHidden #imageContainer.show {
        transform: translateY(0%); }

.specificPortfolio div.showCaseSection {
  border: 10px solid #FFE100;
  background: white; }
  .specificPortfolio div.showCaseSection > div.desktopView,
  .specificPortfolio div.showCaseSection > div.mobileiXView,
  .specificPortfolio div.showCaseSection > div.mobileDefaultView,
  .specificPortfolio div.showCaseSection > div.designConceptSection,
  .specificPortfolio div.showCaseSection > div.conceptKeyPointsListSection {
    margin: 1rem auto; }
    @media (min-width: 1200px) {
      .specificPortfolio div.showCaseSection > div.desktopView div.emptySpacing,
      .specificPortfolio div.showCaseSection > div.mobileiXView div.emptySpacing,
      .specificPortfolio div.showCaseSection > div.mobileDefaultView div.emptySpacing,
      .specificPortfolio div.showCaseSection > div.designConceptSection div.emptySpacing,
      .specificPortfolio div.showCaseSection > div.conceptKeyPointsListSection div.emptySpacing {
        height: 40px; } }
  .specificPortfolio div.showCaseSection div.desktopView button.nextButton {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    /* reset default style */
    position: relative; }
    .specificPortfolio div.showCaseSection div.desktopView button.nextButton .arrowSVG {
      cursor: pointer;
      animation-name: ArrowRightBounceForward;
      animation-duration: 1000ms;
      animation-iteration-count: infinite;
      animation-direction: alternate;
      animation-timing-function: ease-in; }
    .specificPortfolio div.showCaseSection div.desktopView button.nextButton:hover polyline {
      stroke-width: 5; }
    .specificPortfolio div.showCaseSection div.desktopView button.nextButton.btn-outline-secondary:focus, .specificPortfolio div.showCaseSection div.desktopView button.nextButton.btn-outline-secondary:active, .specificPortfolio div.showCaseSection div.desktopView button.nextButton.btn-outline-secondary:hover {
      outline: none;
      box-shadow: unset;
      background: transparent; }
    .specificPortfolio div.showCaseSection div.desktopView button.nextButton:before, .specificPortfolio div.showCaseSection div.desktopView button.nextButton:after {
      position: absolute;
      content: '';
      width: 100%;
      height: 100%;
      opacity: 0;
      transition: all ease-in-out 1000ms;
      background-repeat: no-repeat;
      animation-timing-function: ease-in-out;
      animation-duration: 2000ms;
      animation-fill-mode: forwards; }
    .specificPortfolio div.showCaseSection div.desktopView button.nextButton:before {
      display: none;
      animation-name: leftBubbles;
      top: 0%;
      left: -80%;
      background-image: radial-gradient(circle, #FFE100 20%, transparent 20%), radial-gradient(circle, transparent 20%, #FFE100 20%, transparent 30%), radial-gradient(circle, #FFE100 20%, transparent 20%), radial-gradient(circle, #FFE100 20%, transparent 20%), radial-gradient(circle, transparent 10%, #FFE100 15%, transparent 20%), radial-gradient(circle, #FFE100 20%, transparent 20%), radial-gradient(circle, #FFE100 20%, transparent 20%), radial-gradient(circle, #FFE100 20%, transparent 20%), radial-gradient(circle, #FFE100 20%, transparent 20%);
      background-size: 8px 8px, 20px 20px, 26.66667px 26.66667px, 20px 20px, 13.33333px 13.33333px, 10px 10px, 26.66667px 26.66667px, 10px 10px, 13.33333px 13.33333px;
      background-position: 90% 0%, 100% 10%, 90% 10%, 85% 15%, 90% 25%, 90% 25%, 90% 40%, 90% 55%, 90% 70%; }
    .specificPortfolio div.showCaseSection div.desktopView button.nextButton:after {
      display: none;
      animation-name: rightBubbles;
      top: 0%;
      right: -80%;
      background-image: radial-gradient(circle, #FFE100 20%, transparent 20%), radial-gradient(circle, #FFE100 20%, transparent 20%), radial-gradient(circle, transparent 10%, #FFE100 15%, transparent 20%), radial-gradient(circle, #FFE100 20%, transparent 20%), radial-gradient(circle, #FFE100 20%, transparent 20%), radial-gradient(circle, #FFE100 20%, transparent 20%), radial-gradient(circle, #FFE100 20%, transparent 20%);
      background-size: 26.66667px 26.66667px, 20px 20px, 24px 24px, 20px 20px, 26.66667px 26.66667px, 13.33333px 13.33333px, 20px 20px;
      background-position: 10% 100%, 0% 90%, 10% 20%, 15% 15%, 10% 25%, 0% 25%, 10% 40%, 10% 55%, 10% 70%; }
    .specificPortfolio div.showCaseSection div.desktopView button.nextButton.showBubbles {
      transform: rotate(0deg); }
      .specificPortfolio div.showCaseSection div.desktopView button.nextButton.showBubbles:after, .specificPortfolio div.showCaseSection div.desktopView button.nextButton.showBubbles:before {
        display: block;
        opacity: 1; }
  .specificPortfolio div.showCaseSection div.desktopView .screen {
    position: relative;
    /* screenshot list */ }
    .specificPortfolio div.showCaseSection div.desktopView .screen .filterScreenshot {
      pointer-events: fill;
      position: absolute;
      overflow-x: hidden;
      overflow-y: scroll;
      margin: 3% 11%;
      max-height: 85%;
      height: 100%;
      width: fit-content;
      /* img.screenshot {} */ }
    .specificPortfolio div.showCaseSection div.desktopView .screen ul {
      position: relative; }
      .specificPortfolio div.showCaseSection div.desktopView .screen ul li {
        float: left;
        display: none; }
        .specificPortfolio div.showCaseSection div.desktopView .screen ul li:first-child {
          display: block; }
        .specificPortfolio div.showCaseSection div.desktopView .screen ul li:not(:first-child) {
          position: absolute;
          width: fit-content;
          top: 0;
          left: 100%; }
    .specificPortfolio div.showCaseSection div.desktopView .screen img.desktop {
      position: relative;
      pointer-events: none; }
  .specificPortfolio div.showCaseSection div.mobileiXView button.nextButton {
    z-index: 5;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    transition-property: all;
    transition-duration: 500ms;
    /* reset default style */
    position: relative; }
    .specificPortfolio div.showCaseSection div.mobileiXView button.nextButton .arrowSVG {
      cursor: pointer;
      animation-name: ArrowRightBounceForward;
      animation-duration: 1000ms;
      animation-iteration-count: infinite;
      animation-direction: alternate;
      animation-timing-function: ease-in; }
    .specificPortfolio div.showCaseSection div.mobileiXView button.nextButton:hover polyline {
      stroke-width: 5; }
    .specificPortfolio div.showCaseSection div.mobileiXView button.nextButton.btn-outline-secondary:focus, .specificPortfolio div.showCaseSection div.mobileiXView button.nextButton.btn-outline-secondary:active, .specificPortfolio div.showCaseSection div.mobileiXView button.nextButton.btn-outline-secondary:hover {
      outline: none;
      box-shadow: unset;
      background: transparent; }
    .specificPortfolio div.showCaseSection div.mobileiXView button.nextButton:before, .specificPortfolio div.showCaseSection div.mobileiXView button.nextButton:after {
      position: absolute;
      content: '';
      width: 100%;
      height: 100%;
      opacity: 0;
      transition: all ease-in-out 1000ms;
      background-repeat: no-repeat;
      animation-timing-function: ease-in-out;
      animation-duration: 2000ms;
      animation-fill-mode: forwards; }
    .specificPortfolio div.showCaseSection div.mobileiXView button.nextButton:before {
      display: none;
      animation-name: leftBubbles;
      top: 0%;
      left: -80%;
      background-image: radial-gradient(circle, #FFE100 20%, transparent 20%), radial-gradient(circle, transparent 20%, #FFE100 20%, transparent 30%), radial-gradient(circle, #FFE100 20%, transparent 20%), radial-gradient(circle, #FFE100 20%, transparent 20%), radial-gradient(circle, transparent 10%, #FFE100 15%, transparent 20%), radial-gradient(circle, #FFE100 20%, transparent 20%), radial-gradient(circle, #FFE100 20%, transparent 20%), radial-gradient(circle, #FFE100 20%, transparent 20%), radial-gradient(circle, #FFE100 20%, transparent 20%);
      background-size: 8px 8px, 20px 20px, 26.66667px 26.66667px, 20px 20px, 13.33333px 13.33333px, 10px 10px, 26.66667px 26.66667px, 10px 10px, 13.33333px 13.33333px;
      background-position: 90% 0%, 100% 10%, 90% 10%, 85% 15%, 90% 25%, 90% 25%, 90% 40%, 90% 55%, 90% 70%; }
    .specificPortfolio div.showCaseSection div.mobileiXView button.nextButton:after {
      display: none;
      animation-name: rightBubbles;
      top: 0%;
      right: -80%;
      background-image: radial-gradient(circle, #FFE100 20%, transparent 20%), radial-gradient(circle, #FFE100 20%, transparent 20%), radial-gradient(circle, transparent 10%, #FFE100 15%, transparent 20%), radial-gradient(circle, #FFE100 20%, transparent 20%), radial-gradient(circle, #FFE100 20%, transparent 20%), radial-gradient(circle, #FFE100 20%, transparent 20%), radial-gradient(circle, #FFE100 20%, transparent 20%);
      background-size: 26.66667px 26.66667px, 20px 20px, 24px 24px, 20px 20px, 26.66667px 26.66667px, 13.33333px 13.33333px, 20px 20px;
      background-position: 10% 100%, 0% 90%, 10% 20%, 15% 15%, 10% 25%, 0% 25%, 10% 40%, 10% 55%, 10% 70%; }
    .specificPortfolio div.showCaseSection div.mobileiXView button.nextButton.showBubbles {
      transform: rotate(0deg); }
      .specificPortfolio div.showCaseSection div.mobileiXView button.nextButton.showBubbles:after, .specificPortfolio div.showCaseSection div.mobileiXView button.nextButton.showBubbles:before {
        display: block;
        opacity: 1; }
  .specificPortfolio div.showCaseSection div.mobileiXView .screen {
    position: relative; }
    .specificPortfolio div.showCaseSection div.mobileiXView .screen .filterScreenshot {
      position: absolute;
      overflow: hidden;
      padding-top: 10px;
      height: 460px;
      width: fit-content;
      /* screenshot list */ }
      .specificPortfolio div.showCaseSection div.mobileiXView .screen .filterScreenshot ul {
        position: relative; }
        .specificPortfolio div.showCaseSection div.mobileiXView .screen .filterScreenshot ul li {
          float: left;
          display: none; }
          .specificPortfolio div.showCaseSection div.mobileiXView .screen .filterScreenshot ul li:first-child {
            display: block; }
          .specificPortfolio div.showCaseSection div.mobileiXView .screen .filterScreenshot ul li:not(:first-child) {
            position: absolute;
            width: fit-content;
            top: 0;
            left: 100%; }
      .specificPortfolio div.showCaseSection div.mobileiXView .screen .filterScreenshot img.screenshot {
        border-radius: 25px;
        height: calc(445px);
        max-width: unset; }
    .specificPortfolio div.showCaseSection div.mobileiXView .screen img.iPhone {
      z-index: 2;
      height: 460px;
      max-width: unset; }
  .specificPortfolio div.showCaseSection div.mobileDefaultView .screen {
    position: relative; }
    .specificPortfolio div.showCaseSection div.mobileDefaultView .screen .filterScreenshot {
      position: absolute;
      overflow: hidden;
      padding-top: 54px;
      width: 200px; }
      .specificPortfolio div.showCaseSection div.mobileDefaultView .screen .filterScreenshot img.screenshot {
        height: auto;
        max-width: 200px; }
    .specificPortfolio div.showCaseSection div.mobileDefaultView .screen img.mobileDefault {
      z-index: 2;
      height: 460px;
      max-width: unset; }
  .specificPortfolio div.showCaseSection div.conceptKeyPointsListSection ul li {
    color: #333333;
    display: list-item;
    border: unset;
    font-family: "Didot", Helvetica, sans-serif; }
  .specificPortfolio div.showCaseSection div.designConceptSection * {
    color: #333333; }
  .specificPortfolio div.showCaseSection div.designConceptSection h2 {
    position: relative;
    width: fit-content; }
    .specificPortfolio div.showCaseSection div.designConceptSection h2 > span {
      min-width: 12px;
      transform-origin: 80% 20%;
      display: inline-block;
      transform: scale(0);
      opacity: 0; }
    .specificPortfolio div.showCaseSection div.designConceptSection h2.transiformScale > span {
      /* a little tricky here, to go throught each possible span character */
      /* setup animation */ }
      .specificPortfolio div.showCaseSection div.designConceptSection h2.transiformScale > span:nth-child(1) {
        animation: transiformScale 1s 500ms;
        animation-delay: 100ms;
        animation-fill-mode: forwards; }
      .specificPortfolio div.showCaseSection div.designConceptSection h2.transiformScale > span:nth-child(2) {
        animation: transiformScale 1s 500ms;
        animation-delay: 200ms;
        animation-fill-mode: forwards; }
      .specificPortfolio div.showCaseSection div.designConceptSection h2.transiformScale > span:nth-child(3) {
        animation: transiformScale 1s 500ms;
        animation-delay: 300ms;
        animation-fill-mode: forwards; }
      .specificPortfolio div.showCaseSection div.designConceptSection h2.transiformScale > span:nth-child(4) {
        animation: transiformScale 1s 500ms;
        animation-delay: 400ms;
        animation-fill-mode: forwards; }
      .specificPortfolio div.showCaseSection div.designConceptSection h2.transiformScale > span:nth-child(5) {
        animation: transiformScale 1s 500ms;
        animation-delay: 500ms;
        animation-fill-mode: forwards; }
      .specificPortfolio div.showCaseSection div.designConceptSection h2.transiformScale > span:nth-child(6) {
        animation: transiformScale 1s 500ms;
        animation-delay: 600ms;
        animation-fill-mode: forwards; }
      .specificPortfolio div.showCaseSection div.designConceptSection h2.transiformScale > span:nth-child(7) {
        animation: transiformScale 1s 500ms;
        animation-delay: 700ms;
        animation-fill-mode: forwards; }
      .specificPortfolio div.showCaseSection div.designConceptSection h2.transiformScale > span:nth-child(8) {
        animation: transiformScale 1s 500ms;
        animation-delay: 800ms;
        animation-fill-mode: forwards; }
      .specificPortfolio div.showCaseSection div.designConceptSection h2.transiformScale > span:nth-child(9) {
        animation: transiformScale 1s 500ms;
        animation-delay: 900ms;
        animation-fill-mode: forwards; }
      .specificPortfolio div.showCaseSection div.designConceptSection h2.transiformScale > span:nth-child(10) {
        animation: transiformScale 1s 500ms;
        animation-delay: 1000ms;
        animation-fill-mode: forwards; }
      .specificPortfolio div.showCaseSection div.designConceptSection h2.transiformScale > span:nth-child(11) {
        animation: transiformScale 1s 500ms;
        animation-delay: 1100ms;
        animation-fill-mode: forwards; }
      .specificPortfolio div.showCaseSection div.designConceptSection h2.transiformScale > span:nth-child(12) {
        animation: transiformScale 1s 500ms;
        animation-delay: 1200ms;
        animation-fill-mode: forwards; }
      .specificPortfolio div.showCaseSection div.designConceptSection h2.transiformScale > span:nth-child(13) {
        animation: transiformScale 1s 500ms;
        animation-delay: 1300ms;
        animation-fill-mode: forwards; }
      .specificPortfolio div.showCaseSection div.designConceptSection h2.transiformScale > span:nth-child(14) {
        animation: transiformScale 1s 500ms;
        animation-delay: 1400ms;
        animation-fill-mode: forwards; }
      .specificPortfolio div.showCaseSection div.designConceptSection h2.transiformScale > span:nth-child(15) {
        animation: transiformScale 1s 500ms;
        animation-delay: 1500ms;
        animation-fill-mode: forwards; }
      .specificPortfolio div.showCaseSection div.designConceptSection h2.transiformScale > span:nth-child(16) {
        animation: transiformScale 1s 500ms;
        animation-delay: 1600ms;
        animation-fill-mode: forwards; }
      .specificPortfolio div.showCaseSection div.designConceptSection h2.transiformScale > span:nth-child(17) {
        animation: transiformScale 1s 500ms;
        animation-delay: 1700ms;
        animation-fill-mode: forwards; }
      .specificPortfolio div.showCaseSection div.designConceptSection h2.transiformScale > span:nth-child(18) {
        animation: transiformScale 1s 500ms;
        animation-delay: 1800ms;
        animation-fill-mode: forwards; }
      .specificPortfolio div.showCaseSection div.designConceptSection h2.transiformScale > span:nth-child(19) {
        animation: transiformScale 1s 500ms;
        animation-delay: 1900ms;
        animation-fill-mode: forwards; }
      .specificPortfolio div.showCaseSection div.designConceptSection h2.transiformScale > span:nth-child(20) {
        animation: transiformScale 1s 500ms;
        animation-delay: 2000ms;
        animation-fill-mode: forwards; }
      .specificPortfolio div.showCaseSection div.designConceptSection h2.transiformScale > span:nth-child(21) {
        animation: transiformScale 1s 500ms;
        animation-delay: 2100ms;
        animation-fill-mode: forwards; }
      .specificPortfolio div.showCaseSection div.designConceptSection h2.transiformScale > span:nth-child(22) {
        animation: transiformScale 1s 500ms;
        animation-delay: 2200ms;
        animation-fill-mode: forwards; }
      .specificPortfolio div.showCaseSection div.designConceptSection h2.transiformScale > span:nth-child(23) {
        animation: transiformScale 1s 500ms;
        animation-delay: 2300ms;
        animation-fill-mode: forwards; }
      .specificPortfolio div.showCaseSection div.designConceptSection h2.transiformScale > span:nth-child(24) {
        animation: transiformScale 1s 500ms;
        animation-delay: 2400ms;
        animation-fill-mode: forwards; }
      .specificPortfolio div.showCaseSection div.designConceptSection h2.transiformScale > span:nth-child(25) {
        animation: transiformScale 1s 500ms;
        animation-delay: 2500ms;
        animation-fill-mode: forwards; }
      .specificPortfolio div.showCaseSection div.designConceptSection h2.transiformScale > span:nth-child(26) {
        animation: transiformScale 1s 500ms;
        animation-delay: 2600ms;
        animation-fill-mode: forwards; }
      .specificPortfolio div.showCaseSection div.designConceptSection h2.transiformScale > span:nth-child(27) {
        animation: transiformScale 1s 500ms;
        animation-delay: 2700ms;
        animation-fill-mode: forwards; }
      .specificPortfolio div.showCaseSection div.designConceptSection h2.transiformScale > span:nth-child(28) {
        animation: transiformScale 1s 500ms;
        animation-delay: 2800ms;
        animation-fill-mode: forwards; }
      .specificPortfolio div.showCaseSection div.designConceptSection h2.transiformScale > span:nth-child(29) {
        animation: transiformScale 1s 500ms;
        animation-delay: 2900ms;
        animation-fill-mode: forwards; }
      .specificPortfolio div.showCaseSection div.designConceptSection h2.transiformScale > span:nth-child(30) {
        animation: transiformScale 1s 500ms;
        animation-delay: 3000ms;
        animation-fill-mode: forwards; }
      .specificPortfolio div.showCaseSection div.designConceptSection h2.transiformScale > span:nth-child(31) {
        animation: transiformScale 1s 500ms;
        animation-delay: 3100ms;
        animation-fill-mode: forwards; }
      .specificPortfolio div.showCaseSection div.designConceptSection h2.transiformScale > span:nth-child(32) {
        animation: transiformScale 1s 500ms;
        animation-delay: 3200ms;
        animation-fill-mode: forwards; }
      .specificPortfolio div.showCaseSection div.designConceptSection h2.transiformScale > span:nth-child(33) {
        animation: transiformScale 1s 500ms;
        animation-delay: 3300ms;
        animation-fill-mode: forwards; }
      .specificPortfolio div.showCaseSection div.designConceptSection h2.transiformScale > span:nth-child(34) {
        animation: transiformScale 1s 500ms;
        animation-delay: 3400ms;
        animation-fill-mode: forwards; }
      .specificPortfolio div.showCaseSection div.designConceptSection h2.transiformScale > span:nth-child(35) {
        animation: transiformScale 1s 500ms;
        animation-delay: 3500ms;
        animation-fill-mode: forwards; }
      .specificPortfolio div.showCaseSection div.designConceptSection h2.transiformScale > span:nth-child(36) {
        animation: transiformScale 1s 500ms;
        animation-delay: 3600ms;
        animation-fill-mode: forwards; }
      .specificPortfolio div.showCaseSection div.designConceptSection h2.transiformScale > span:nth-child(37) {
        animation: transiformScale 1s 500ms;
        animation-delay: 3700ms;
        animation-fill-mode: forwards; }
      .specificPortfolio div.showCaseSection div.designConceptSection h2.transiformScale > span:nth-child(38) {
        animation: transiformScale 1s 500ms;
        animation-delay: 3800ms;
        animation-fill-mode: forwards; }
      .specificPortfolio div.showCaseSection div.designConceptSection h2.transiformScale > span:nth-child(39) {
        animation: transiformScale 1s 500ms;
        animation-delay: 3900ms;
        animation-fill-mode: forwards; }
      .specificPortfolio div.showCaseSection div.designConceptSection h2.transiformScale > span:nth-child(40) {
        animation: transiformScale 1s 500ms;
        animation-delay: 4000ms;
        animation-fill-mode: forwards; }
      .specificPortfolio div.showCaseSection div.designConceptSection h2.transiformScale > span:nth-child(41) {
        animation: transiformScale 1s 500ms;
        animation-delay: 4100ms;
        animation-fill-mode: forwards; }
      .specificPortfolio div.showCaseSection div.designConceptSection h2.transiformScale > span:nth-child(42) {
        animation: transiformScale 1s 500ms;
        animation-delay: 4200ms;
        animation-fill-mode: forwards; }
      .specificPortfolio div.showCaseSection div.designConceptSection h2.transiformScale > span:nth-child(43) {
        animation: transiformScale 1s 500ms;
        animation-delay: 4300ms;
        animation-fill-mode: forwards; }
      .specificPortfolio div.showCaseSection div.designConceptSection h2.transiformScale > span:nth-child(44) {
        animation: transiformScale 1s 500ms;
        animation-delay: 4400ms;
        animation-fill-mode: forwards; }
      .specificPortfolio div.showCaseSection div.designConceptSection h2.transiformScale > span:nth-child(45) {
        animation: transiformScale 1s 500ms;
        animation-delay: 4500ms;
        animation-fill-mode: forwards; }
      .specificPortfolio div.showCaseSection div.designConceptSection h2.transiformScale > span:nth-child(46) {
        animation: transiformScale 1s 500ms;
        animation-delay: 4600ms;
        animation-fill-mode: forwards; }
      .specificPortfolio div.showCaseSection div.designConceptSection h2.transiformScale > span:nth-child(47) {
        animation: transiformScale 1s 500ms;
        animation-delay: 4700ms;
        animation-fill-mode: forwards; }
      .specificPortfolio div.showCaseSection div.designConceptSection h2.transiformScale > span:nth-child(48) {
        animation: transiformScale 1s 500ms;
        animation-delay: 4800ms;
        animation-fill-mode: forwards; }
      .specificPortfolio div.showCaseSection div.designConceptSection h2.transiformScale > span:nth-child(49) {
        animation: transiformScale 1s 500ms;
        animation-delay: 4900ms;
        animation-fill-mode: forwards; }
      .specificPortfolio div.showCaseSection div.designConceptSection h2.transiformScale > span:nth-child(50) {
        animation: transiformScale 1s 500ms;
        animation-delay: 5000ms;
        animation-fill-mode: forwards; }
      .specificPortfolio div.showCaseSection div.designConceptSection h2.transiformScale > span:nth-child(51) {
        animation: transiformScale 1s 500ms;
        animation-delay: 5100ms;
        animation-fill-mode: forwards; }
      .specificPortfolio div.showCaseSection div.designConceptSection h2.transiformScale > span:nth-child(52) {
        animation: transiformScale 1s 500ms;
        animation-delay: 5200ms;
        animation-fill-mode: forwards; }
      .specificPortfolio div.showCaseSection div.designConceptSection h2.transiformScale > span:nth-child(53) {
        animation: transiformScale 1s 500ms;
        animation-delay: 5300ms;
        animation-fill-mode: forwards; }
      .specificPortfolio div.showCaseSection div.designConceptSection h2.transiformScale > span:nth-child(54) {
        animation: transiformScale 1s 500ms;
        animation-delay: 5400ms;
        animation-fill-mode: forwards; }
      .specificPortfolio div.showCaseSection div.designConceptSection h2.transiformScale > span:nth-child(55) {
        animation: transiformScale 1s 500ms;
        animation-delay: 5500ms;
        animation-fill-mode: forwards; }
      .specificPortfolio div.showCaseSection div.designConceptSection h2.transiformScale > span:nth-child(56) {
        animation: transiformScale 1s 500ms;
        animation-delay: 5600ms;
        animation-fill-mode: forwards; }
      .specificPortfolio div.showCaseSection div.designConceptSection h2.transiformScale > span:nth-child(57) {
        animation: transiformScale 1s 500ms;
        animation-delay: 5700ms;
        animation-fill-mode: forwards; }
      .specificPortfolio div.showCaseSection div.designConceptSection h2.transiformScale > span:nth-child(58) {
        animation: transiformScale 1s 500ms;
        animation-delay: 5800ms;
        animation-fill-mode: forwards; }
      .specificPortfolio div.showCaseSection div.designConceptSection h2.transiformScale > span:nth-child(59) {
        animation: transiformScale 1s 500ms;
        animation-delay: 5900ms;
        animation-fill-mode: forwards; }
      .specificPortfolio div.showCaseSection div.designConceptSection h2.transiformScale > span:nth-child(60) {
        animation: transiformScale 1s 500ms;
        animation-delay: 6000ms;
        animation-fill-mode: forwards; }
      .specificPortfolio div.showCaseSection div.designConceptSection h2.transiformScale > span:nth-child(61) {
        animation: transiformScale 1s 500ms;
        animation-delay: 6100ms;
        animation-fill-mode: forwards; }
      .specificPortfolio div.showCaseSection div.designConceptSection h2.transiformScale > span:nth-child(62) {
        animation: transiformScale 1s 500ms;
        animation-delay: 6200ms;
        animation-fill-mode: forwards; }
      .specificPortfolio div.showCaseSection div.designConceptSection h2.transiformScale > span:nth-child(63) {
        animation: transiformScale 1s 500ms;
        animation-delay: 6300ms;
        animation-fill-mode: forwards; }
      .specificPortfolio div.showCaseSection div.designConceptSection h2.transiformScale > span:nth-child(64) {
        animation: transiformScale 1s 500ms;
        animation-delay: 6400ms;
        animation-fill-mode: forwards; }
      .specificPortfolio div.showCaseSection div.designConceptSection h2.transiformScale > span:nth-child(65) {
        animation: transiformScale 1s 500ms;
        animation-delay: 6500ms;
        animation-fill-mode: forwards; }
      .specificPortfolio div.showCaseSection div.designConceptSection h2.transiformScale > span:nth-child(66) {
        animation: transiformScale 1s 500ms;
        animation-delay: 6600ms;
        animation-fill-mode: forwards; }
      .specificPortfolio div.showCaseSection div.designConceptSection h2.transiformScale > span:nth-child(67) {
        animation: transiformScale 1s 500ms;
        animation-delay: 6700ms;
        animation-fill-mode: forwards; }
      .specificPortfolio div.showCaseSection div.designConceptSection h2.transiformScale > span:nth-child(68) {
        animation: transiformScale 1s 500ms;
        animation-delay: 6800ms;
        animation-fill-mode: forwards; }
      .specificPortfolio div.showCaseSection div.designConceptSection h2.transiformScale > span:nth-child(69) {
        animation: transiformScale 1s 500ms;
        animation-delay: 6900ms;
        animation-fill-mode: forwards; }
      .specificPortfolio div.showCaseSection div.designConceptSection h2.transiformScale > span:nth-child(70) {
        animation: transiformScale 1s 500ms;
        animation-delay: 7000ms;
        animation-fill-mode: forwards; }
      .specificPortfolio div.showCaseSection div.designConceptSection h2.transiformScale > span:nth-child(71) {
        animation: transiformScale 1s 500ms;
        animation-delay: 7100ms;
        animation-fill-mode: forwards; }
      .specificPortfolio div.showCaseSection div.designConceptSection h2.transiformScale > span:nth-child(72) {
        animation: transiformScale 1s 500ms;
        animation-delay: 7200ms;
        animation-fill-mode: forwards; }
      .specificPortfolio div.showCaseSection div.designConceptSection h2.transiformScale > span:nth-child(73) {
        animation: transiformScale 1s 500ms;
        animation-delay: 7300ms;
        animation-fill-mode: forwards; }
      .specificPortfolio div.showCaseSection div.designConceptSection h2.transiformScale > span:nth-child(74) {
        animation: transiformScale 1s 500ms;
        animation-delay: 7400ms;
        animation-fill-mode: forwards; }
      .specificPortfolio div.showCaseSection div.designConceptSection h2.transiformScale > span:nth-child(75) {
        animation: transiformScale 1s 500ms;
        animation-delay: 7500ms;
        animation-fill-mode: forwards; }
      .specificPortfolio div.showCaseSection div.designConceptSection h2.transiformScale > span:nth-child(76) {
        animation: transiformScale 1s 500ms;
        animation-delay: 7600ms;
        animation-fill-mode: forwards; }
      .specificPortfolio div.showCaseSection div.designConceptSection h2.transiformScale > span:nth-child(77) {
        animation: transiformScale 1s 500ms;
        animation-delay: 7700ms;
        animation-fill-mode: forwards; }
      .specificPortfolio div.showCaseSection div.designConceptSection h2.transiformScale > span:nth-child(78) {
        animation: transiformScale 1s 500ms;
        animation-delay: 7800ms;
        animation-fill-mode: forwards; }
      .specificPortfolio div.showCaseSection div.designConceptSection h2.transiformScale > span:nth-child(79) {
        animation: transiformScale 1s 500ms;
        animation-delay: 7900ms;
        animation-fill-mode: forwards; }
      .specificPortfolio div.showCaseSection div.designConceptSection h2.transiformScale > span:nth-child(80) {
        animation: transiformScale 1s 500ms;
        animation-delay: 8000ms;
        animation-fill-mode: forwards; }
      .specificPortfolio div.showCaseSection div.designConceptSection h2.transiformScale > span:nth-child(81) {
        animation: transiformScale 1s 500ms;
        animation-delay: 8100ms;
        animation-fill-mode: forwards; }
      .specificPortfolio div.showCaseSection div.designConceptSection h2.transiformScale > span:nth-child(82) {
        animation: transiformScale 1s 500ms;
        animation-delay: 8200ms;
        animation-fill-mode: forwards; }
      .specificPortfolio div.showCaseSection div.designConceptSection h2.transiformScale > span:nth-child(83) {
        animation: transiformScale 1s 500ms;
        animation-delay: 8300ms;
        animation-fill-mode: forwards; }
      .specificPortfolio div.showCaseSection div.designConceptSection h2.transiformScale > span:nth-child(84) {
        animation: transiformScale 1s 500ms;
        animation-delay: 8400ms;
        animation-fill-mode: forwards; }
      .specificPortfolio div.showCaseSection div.designConceptSection h2.transiformScale > span:nth-child(85) {
        animation: transiformScale 1s 500ms;
        animation-delay: 8500ms;
        animation-fill-mode: forwards; }
      .specificPortfolio div.showCaseSection div.designConceptSection h2.transiformScale > span:nth-child(86) {
        animation: transiformScale 1s 500ms;
        animation-delay: 8600ms;
        animation-fill-mode: forwards; }
      .specificPortfolio div.showCaseSection div.designConceptSection h2.transiformScale > span:nth-child(87) {
        animation: transiformScale 1s 500ms;
        animation-delay: 8700ms;
        animation-fill-mode: forwards; }
      .specificPortfolio div.showCaseSection div.designConceptSection h2.transiformScale > span:nth-child(88) {
        animation: transiformScale 1s 500ms;
        animation-delay: 8800ms;
        animation-fill-mode: forwards; }
      .specificPortfolio div.showCaseSection div.designConceptSection h2.transiformScale > span:nth-child(89) {
        animation: transiformScale 1s 500ms;
        animation-delay: 8900ms;
        animation-fill-mode: forwards; }
      .specificPortfolio div.showCaseSection div.designConceptSection h2.transiformScale > span:nth-child(90) {
        animation: transiformScale 1s 500ms;
        animation-delay: 9000ms;
        animation-fill-mode: forwards; }
      .specificPortfolio div.showCaseSection div.designConceptSection h2.transiformScale > span:nth-child(91) {
        animation: transiformScale 1s 500ms;
        animation-delay: 9100ms;
        animation-fill-mode: forwards; }
      .specificPortfolio div.showCaseSection div.designConceptSection h2.transiformScale > span:nth-child(92) {
        animation: transiformScale 1s 500ms;
        animation-delay: 9200ms;
        animation-fill-mode: forwards; }
      .specificPortfolio div.showCaseSection div.designConceptSection h2.transiformScale > span:nth-child(93) {
        animation: transiformScale 1s 500ms;
        animation-delay: 9300ms;
        animation-fill-mode: forwards; }
      .specificPortfolio div.showCaseSection div.designConceptSection h2.transiformScale > span:nth-child(94) {
        animation: transiformScale 1s 500ms;
        animation-delay: 9400ms;
        animation-fill-mode: forwards; }
      .specificPortfolio div.showCaseSection div.designConceptSection h2.transiformScale > span:nth-child(95) {
        animation: transiformScale 1s 500ms;
        animation-delay: 9500ms;
        animation-fill-mode: forwards; }
      .specificPortfolio div.showCaseSection div.designConceptSection h2.transiformScale > span:nth-child(96) {
        animation: transiformScale 1s 500ms;
        animation-delay: 9600ms;
        animation-fill-mode: forwards; }
      .specificPortfolio div.showCaseSection div.designConceptSection h2.transiformScale > span:nth-child(97) {
        animation: transiformScale 1s 500ms;
        animation-delay: 9700ms;
        animation-fill-mode: forwards; }
      .specificPortfolio div.showCaseSection div.designConceptSection h2.transiformScale > span:nth-child(98) {
        animation: transiformScale 1s 500ms;
        animation-delay: 9800ms;
        animation-fill-mode: forwards; }
      .specificPortfolio div.showCaseSection div.designConceptSection h2.transiformScale > span:nth-child(99) {
        animation: transiformScale 1s 500ms;
        animation-delay: 9900ms;
        animation-fill-mode: forwards; }
      .specificPortfolio div.showCaseSection div.designConceptSection h2.transiformScale > span:nth-child(100) {
        animation: transiformScale 1s 500ms;
        animation-delay: 10000ms;
        animation-fill-mode: forwards; }
    .specificPortfolio div.showCaseSection div.designConceptSection h2::before {
      position: absolute;
      left: 0;
      bottom: 0;
      content: ' ';
      width: 110%;
      height: 10px;
      opacity: 0.5;
      background-color: #FFE100; }
      @media (min-width: 768px) {
        .specificPortfolio div.showCaseSection div.designConceptSection h2::before {
          width: 150%; } }
      @media (min-width: 992px) {
        .specificPortfolio div.showCaseSection div.designConceptSection h2::before {
          width: 140%; } }
      @media (min-width: 1200px) {
        .specificPortfolio div.showCaseSection div.designConceptSection h2::before {
          width: 130%; } }
  .specificPortfolio div.showCaseSection div.designConceptSection p {
    font-size: 1.1em; }

.specificPortfolio div.nextIndicatorSection div.overflowHidden {
  overflow: hidden; }
  .specificPortfolio div.nextIndicatorSection div.overflowHidden div#scrollTransfromTarget {
    transition: all 200ms; }

.specificPortfolio div.nextIndicatorSection .backgroundConver {
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: radial-gradient(circle, #333333 0%, #FFFFFF 100%);
  background-size: cover;
  background-position: top center;
  opacity: 0.5; }

.specificPortfolio div.nextIndicatorSection .cover {
  height: 50vh; }

.specificPortfolio div.nextIndicatorSection a {
  color: white;
  /* for transition showText */ }
  .specificPortfolio div.nextIndicatorSection a h4,
  .specificPortfolio div.nextIndicatorSection a h5 {
    z-index: 2;
    font-family: "Lekton", Helvetica, sans-serif; }
  .specificPortfolio div.nextIndicatorSection a h4 {
    font-style: italic; }
  .specificPortfolio div.nextIndicatorSection a:hover {
    text-decoration: none; }
  .specificPortfolio div.nextIndicatorSection a img.arrowDown {
    height: 52px; }
  .specificPortfolio div.nextIndicatorSection a.textContentOpacity * {
    transition: opacity 500ms ease-in;
    opacity: 0; }

.specificPortfolio div.nextIndicatorSection .isShowText a.textContentOpacity * {
  opacity: 1; }

.service .mainSections * {
  color: #333333;
  font-family: "Lekton"; }
  .service .mainSections * h2 {
    font-weight: bold; }

.service .mainSections .heroToggleSection {
  /* specific style */ }
  .service .mainSections .heroToggleSection h2 {
    margin: 3rem auto; }
    @media (min-width: 992px) {
      .service .mainSections .heroToggleSection h2 {
        margin: 5rem auto; } }
    @media (min-width: 1200px) {
      .service .mainSections .heroToggleSection h2 {
        margin: 6rem auto; } }
  .service .mainSections .heroToggleSection div.banner {
    /* general style */
    min-height: 330px; }
    .service .mainSections .heroToggleSection div.banner div.content {
      display: none;
      width: 100%; }
      .service .mainSections .heroToggleSection div.banner div.content p {
        white-space: pre-wrap;
        width: 80%; }
    .service .mainSections .heroToggleSection div.banner:hover div.content {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center; }
    .service .mainSections .heroToggleSection div.banner:hover div.heroText {
      display: none; }
  .service .mainSections .heroToggleSection .appBanner {
    background-color: #EAEAEA; }
    .service .mainSections .heroToggleSection .appBanner div.content {
      background-color: #333333; }
      .service .mainSections .heroToggleSection .appBanner div.content * {
        color: white; }
      .service .mainSections .heroToggleSection .appBanner div.content .textDecorationAnchor {
        position: relative;
        width: fit-content; }
        .service .mainSections .heroToggleSection .appBanner div.content .textDecorationAnchor b {
          z-index: 2;
          position: relative; }
        .service .mainSections .heroToggleSection .appBanner div.content .textDecorationAnchor div.textDecoration {
          width: 80%;
          height: 1px;
          background-color: #FFE100;
          position: absolute;
          bottom: 0px;
          left: 10%; }
  .service .mainSections .heroToggleSection .webBanner {
    background-color: #333333; }
    .service .mainSections .heroToggleSection .webBanner * {
      color: white; }
    .service .mainSections .heroToggleSection .webBanner div.content {
      background-color: #EAEAEA; }
      .service .mainSections .heroToggleSection .webBanner div.content * {
        color: #333333; }
      .service .mainSections .heroToggleSection .webBanner div.content .textDecorationAnchor {
        position: relative;
        width: fit-content; }
        .service .mainSections .heroToggleSection .webBanner div.content .textDecorationAnchor b {
          z-index: 2;
          position: relative; }
        .service .mainSections .heroToggleSection .webBanner div.content .textDecorationAnchor div.textDecoration {
          position: absolute;
          left: 10%;
          bottom: 2px;
          width: 80%;
          height: 15px;
          background-color: #FFE100; }

.service .mainSections .thematicBanner {
  border: 5px solid #FFE100; }
  .service .mainSections .thematicBanner h3 {
    font-style: italic;
    font-weight: normal; }

.job .mainSections * {
  color: #333333; }

.job .mainSections h3 {
  font-family: "Lekton", Helvetica, sans-serif;
  letter-spacing: 0.1em; }

.job .mainSections div.toggleSection {
  border: 1px solid #979797;
  font-family: "Lekton", Helvetica, sans-serif; }
  .job .mainSections div.toggleSection a {
    cursor: pointer; }
  .job .mainSections div.toggleSection div.collaborate {
    background: #333333; }
    .job .mainSections div.toggleSection div.collaborate * {
      color: white; }
  .job .mainSections div.toggleSection div.contact a,
  .job .mainSections div.toggleSection div.contact button {
    text-decoration: none; }
  .job .mainSections div.toggleSection div.contact:hover {
    background: #333333; }
    .job .mainSections div.toggleSection div.contact:hover * {
      color: white; }
    .job .mainSections div.toggleSection div.contact:hover ~ div.collaborate {
      background: white; }
      .job .mainSections div.toggleSection div.contact:hover ~ div.collaborate * {
        color: #333333; }

.job .mainSections div.jobOpportunitySection {
  /* animation */
  /* hide for default */
  opacity: 0;
  clip-path: inset(0% 0% 80% 0%); }
  .job .mainSections div.jobOpportunitySection * {
    color: #333333; }
  .job .mainSections div.jobOpportunitySection .textDecorationAnchor {
    position: relative;
    width: fit-content; }
    .job .mainSections div.jobOpportunitySection .textDecorationAnchor h2 {
      font-family: "GothamNarrow", Helvetica, sans-serif;
      z-index: 2;
      position: relative; }
    .job .mainSections div.jobOpportunitySection .textDecorationAnchor div.textDecoration {
      width: 116%;
      height: 14px;
      opacity: 0.9;
      background-color: #FFE100;
      position: absolute;
      bottom: 3px;
      left: -8%; }
  .job .mainSections div.jobOpportunitySection p {
    text-align: center; }
    .job .mainSections div.jobOpportunitySection p a,
    .job .mainSections div.jobOpportunitySection p button {
      cursor: pointer;
      font-family: "GothamNarrow", Helvetica, sans-serif;
      color: #FFE100; }
  .job .mainSections div.jobOpportunitySection.showContent {
    animation: slideDownAnimation;
    animation-timing-function: ease-in-out;
    animation-duration: 1s;
    animation-fill-mode: forwards; }

.job .mainSections form {
  margin-right: -15px;
  margin-left: -15px;
  position: relative; }
  .job .mainSections form.borderForm {
    border: 7px groove rgba(51, 51, 51, 0.58); }
    .job .mainSections form.borderForm label {
      font-weight: bold;
      /* @media (min-width: 576px) {
            text-align: center;
          }
          @media (min-width: 768px) {
            text-align: right;
          } */ }
      .job .mainSections form.borderForm label sup {
        color: var(--orange); }
    .job .mainSections form.borderForm input {
      border-width: 0px;
      background: transparent;
      border-radius: 0px;
      border-bottom: 1px dashed #999999; }
      .job .mainSections form.borderForm input:focus {
        box-shadow: unset; }
      .job .mainSections form.borderForm input:not([value=""]) {
        border-bottom: 1px solid #333333; }
    .job .mainSections form.borderForm textarea {
      background: transparent;
      min-height: 8rem;
      border-color: #999999;
      border-style: dashed; }
      .job .mainSections form.borderForm textarea.filled {
        border-color: #333333;
        border-style: solid; }
      .job .mainSections form.borderForm textarea:focus {
        box-shadow: unset; }
    .job .mainSections form.borderForm button[type='submit'] {
      color: #333333;
      background-color: transparent;
      border-width: 0px;
      font-weight: bold; }

.contact .mainSections {
  overflow: hidden; }
  .contact .mainSections .contactSection {
    min-height: 320px; }
    @media (min-width: 992px) {
      .contact .mainSections .contactSection {
        min-height: 400px; } }
    .contact .mainSections .contactSection .titleBorder {
      background: white;
      position: absolute;
      border: 7px double #EAEAEA;
      height: 300px;
      width: 300px; }
      @media (min-width: 992px) {
        .contact .mainSections .contactSection .titleBorder {
          height: 350px;
          width: 350px; } }
    .contact .mainSections .contactSection a.socialLink {
      color: #FFE100; }
  .contact .mainSections .logoContainer div.logoCharContainer {
    max-width: 350px; }
    .contact .mainSections .logoContainer div.logoCharContainer img.charImg:nth-child(1) {
      transform: translateX(400%) rotate(90deg);
      opacity: 0; }
      .contact .mainSections .logoContainer div.logoCharContainer img.charImg:nth-child(1).logoRollingIn {
        animation-name: logoRollingIn;
        animation-fill-mode: forwards;
        animation-duration: 3000ms;
        animation-delay: 100ms; }

@keyframes logoRollingIn {
  0% {
    opacity: 0;
    transform: translateX(400%) rotate(90deg); }
  30% {
    opacity: 1; }
  100% {
    transform: translateX(0) rotate(0deg);
    opacity: 1; } }
    .contact .mainSections .logoContainer div.logoCharContainer img.charImg:nth-child(2) {
      transform: translateX(500%) rotate(180deg);
      opacity: 0; }
      .contact .mainSections .logoContainer div.logoCharContainer img.charImg:nth-child(2).logoRollingIn {
        animation-name: logoRollingIn;
        animation-fill-mode: forwards;
        animation-duration: 3000ms;
        animation-delay: 200ms; }

@keyframes logoRollingIn {
  0% {
    opacity: 0;
    transform: translateX(500%) rotate(180deg); }
  30% {
    opacity: 1; }
  100% {
    transform: translateX(0) rotate(0deg);
    opacity: 1; } }
    .contact .mainSections .logoContainer div.logoCharContainer img.charImg:nth-child(3) {
      transform: translateX(600%) rotate(270deg);
      opacity: 0; }
      .contact .mainSections .logoContainer div.logoCharContainer img.charImg:nth-child(3).logoRollingIn {
        animation-name: logoRollingIn;
        animation-fill-mode: forwards;
        animation-duration: 3000ms;
        animation-delay: 300ms; }

@keyframes logoRollingIn {
  0% {
    opacity: 0;
    transform: translateX(600%) rotate(270deg); }
  30% {
    opacity: 1; }
  100% {
    transform: translateX(0) rotate(0deg);
    opacity: 1; } }
    .contact .mainSections .logoContainer div.logoCharContainer img.charImg:nth-child(4) {
      transform: translateX(700%) rotate(360deg);
      opacity: 0; }
      .contact .mainSections .logoContainer div.logoCharContainer img.charImg:nth-child(4).logoRollingIn {
        animation-name: logoRollingIn;
        animation-fill-mode: forwards;
        animation-duration: 3000ms;
        animation-delay: 400ms; }

@keyframes logoRollingIn {
  0% {
    opacity: 0;
    transform: translateX(700%) rotate(360deg); }
  30% {
    opacity: 1; }
  100% {
    transform: translateX(0) rotate(0deg);
    opacity: 1; } }
    .contact .mainSections .logoContainer div.logoCharContainer img.charImg:nth-child(5) {
      transform: translateX(800%) rotate(450deg);
      opacity: 0; }
      .contact .mainSections .logoContainer div.logoCharContainer img.charImg:nth-child(5).logoRollingIn {
        animation-name: logoRollingIn;
        animation-fill-mode: forwards;
        animation-duration: 3000ms;
        animation-delay: 500ms; }

@keyframes logoRollingIn {
  0% {
    opacity: 0;
    transform: translateX(800%) rotate(450deg); }
  30% {
    opacity: 1; }
  100% {
    transform: translateX(0) rotate(0deg);
    opacity: 1; } }
    .contact .mainSections .logoContainer div.logoCharContainer img.charImg:nth-child(6) {
      transform: translateX(900%) rotate(540deg);
      opacity: 0; }
      .contact .mainSections .logoContainer div.logoCharContainer img.charImg:nth-child(6).logoRollingIn {
        animation-name: logoRollingIn;
        animation-fill-mode: forwards;
        animation-duration: 3000ms;
        animation-delay: 600ms; }

@keyframes logoRollingIn {
  0% {
    opacity: 0;
    transform: translateX(900%) rotate(540deg); }
  30% {
    opacity: 1; }
  100% {
    transform: translateX(0) rotate(0deg);
    opacity: 1; } }
  .contact .mainSections .logoContainer div.bricks > span {
    display: inline-block;
    position: relative;
    height: 5px;
    background-color: #FFE100;
    margin-left: 5px;
    margin-right: 5px; }
    .contact .mainSections .logoContainer div.bricks > span:nth-child(1) {
      /* animation settings */
      transform: translateX(700%) translateY(-1100%) rotate(5deg);
      width: 0;
      /* NOTE: brickFadeIn animation must set here as use caculation from scss */ }
      .contact .mainSections .logoContainer div.bricks > span:nth-child(1).brickFadeIn {
        animation-name: brickFadeIn;
        animation-fill-mode: forwards;
        animation-duration: 1000ms;
        animation-delay: 100ms; }

@keyframes brickFadeIn {
  from {
    transform: translateX(700%) translateY(-1100%) rotate(5deg);
    width: 0; }
  to {
    transform: translateX(0) translateY(0) rotate(0deg);
    width: 48.33333px; } }
    .contact .mainSections .logoContainer div.bricks > span:nth-child(2) {
      /* animation settings */
      transform: translateX(800%) translateY(-1200%) rotate(10deg);
      width: 0;
      /* NOTE: brickFadeIn animation must set here as use caculation from scss */ }
      .contact .mainSections .logoContainer div.bricks > span:nth-child(2).brickFadeIn {
        animation-name: brickFadeIn;
        animation-fill-mode: forwards;
        animation-duration: 1000ms;
        animation-delay: 200ms; }

@keyframes brickFadeIn {
  from {
    transform: translateX(800%) translateY(-1200%) rotate(10deg);
    width: 0; }
  to {
    transform: translateX(0) translateY(0) rotate(0deg);
    width: 48.33333px; } }
    .contact .mainSections .logoContainer div.bricks > span:nth-child(3) {
      /* animation settings */
      transform: translateX(900%) translateY(-1300%) rotate(15deg);
      width: 0;
      /* NOTE: brickFadeIn animation must set here as use caculation from scss */ }
      .contact .mainSections .logoContainer div.bricks > span:nth-child(3).brickFadeIn {
        animation-name: brickFadeIn;
        animation-fill-mode: forwards;
        animation-duration: 1000ms;
        animation-delay: 300ms; }

@keyframes brickFadeIn {
  from {
    transform: translateX(900%) translateY(-1300%) rotate(15deg);
    width: 0; }
  to {
    transform: translateX(0) translateY(0) rotate(0deg);
    width: 48.33333px; } }
    .contact .mainSections .logoContainer div.bricks > span:nth-child(4) {
      /* animation settings */
      transform: translateX(1000%) translateY(-1400%) rotate(20deg);
      width: 0;
      /* NOTE: brickFadeIn animation must set here as use caculation from scss */ }
      .contact .mainSections .logoContainer div.bricks > span:nth-child(4).brickFadeIn {
        animation-name: brickFadeIn;
        animation-fill-mode: forwards;
        animation-duration: 1000ms;
        animation-delay: 400ms; }

@keyframes brickFadeIn {
  from {
    transform: translateX(1000%) translateY(-1400%) rotate(20deg);
    width: 0; }
  to {
    transform: translateX(0) translateY(0) rotate(0deg);
    width: 48.33333px; } }
    .contact .mainSections .logoContainer div.bricks > span:nth-child(5) {
      /* animation settings */
      transform: translateX(1100%) translateY(-1500%) rotate(25deg);
      width: 0;
      /* NOTE: brickFadeIn animation must set here as use caculation from scss */ }
      .contact .mainSections .logoContainer div.bricks > span:nth-child(5).brickFadeIn {
        animation-name: brickFadeIn;
        animation-fill-mode: forwards;
        animation-duration: 1000ms;
        animation-delay: 500ms; }

@keyframes brickFadeIn {
  from {
    transform: translateX(1100%) translateY(-1500%) rotate(25deg);
    width: 0; }
  to {
    transform: translateX(0) translateY(0) rotate(0deg);
    width: 48.33333px; } }
    .contact .mainSections .logoContainer div.bricks > span:nth-child(6) {
      /* animation settings */
      transform: translateX(1200%) translateY(-1600%) rotate(30deg);
      width: 0;
      /* NOTE: brickFadeIn animation must set here as use caculation from scss */ }
      .contact .mainSections .logoContainer div.bricks > span:nth-child(6).brickFadeIn {
        animation-name: brickFadeIn;
        animation-fill-mode: forwards;
        animation-duration: 1000ms;
        animation-delay: 600ms; }

@keyframes brickFadeIn {
  from {
    transform: translateX(1200%) translateY(-1600%) rotate(30deg);
    width: 0; }
  to {
    transform: translateX(0) translateY(0) rotate(0deg);
    width: 48.33333px; } }
  .contact .mainSections form {
    margin-right: -15px;
    margin-left: -15px;
    position: relative; }
    .contact .mainSections form.borderForm {
      border: 7px groove rgba(51, 51, 51, 0.58); }
      .contact .mainSections form.borderForm label {
        font-weight: bold;
        /* @media (min-width: 576px) {
          text-align: center;
        }
        @media (min-width: 768px) {
          text-align: right;
        } */ }
        .contact .mainSections form.borderForm label sup {
          color: var(--orange); }
      .contact .mainSections form.borderForm input {
        border-width: 0px;
        background: transparent;
        border-radius: 0px;
        border-bottom: 1px dashed #999999; }
        .contact .mainSections form.borderForm input:focus {
          box-shadow: unset; }
        .contact .mainSections form.borderForm input:not([value=""]) {
          border-bottom: 1px solid #333333; }
      .contact .mainSections form.borderForm textarea {
        background: transparent;
        min-height: 8rem;
        border-color: #999999;
        border-style: dashed; }
        .contact .mainSections form.borderForm textarea.filled {
          border-color: #333333;
          border-style: solid; }
        .contact .mainSections form.borderForm textarea:focus {
          box-shadow: unset; }
      .contact .mainSections form.borderForm button[type='submit'] {
        color: #333333;
        background-color: transparent;
        border-width: 0px;
        font-weight: bold; }

.error {
  /* viewPort height mins footer */
  min-height: calc(100vh - 40px); }
